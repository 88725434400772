<mat-toolbar class="nav" color="primary">
    <button type="button" class="menuButton  visible-sm" mat-icon-button style="z-index: 1;" (click)="toggleSidebar()"
        exceptclick>
        <mat-icon aria-label="Side nav toggle icon" exceptclick>menu</mat-icon>
    </button>
    <span class="nav-spacer"></span>
    <div class="profile hide-sm">
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start">
            <div fxFlex="10" fxLayoutAlign="start start">
                <button type="button" class="menuButton" mat-icon-button style="z-index: 1;" (click)="toggleSidebar()"
                    exceptclick>
                    <mat-icon aria-label="Side nav toggle icon" exceptclick>menu</mat-icon>
                </button>
                <div class="nav-brand" title="{{subTitle | translate}}" exceptclick fxLayout="row" fxLayoutGap="0.5vw"
                    fxLayoutAlign="center center">
                    <img fxFlex id="topNavLogo" src="assets/images/logo.png"
                        class="circle z-depth-2 responsive-img activator" alt="{{title | translate}}"
                        (click)="toggleSidebar()">
                    <img fxFlex src="assets/images/logo-text.png" (click)="toggleSidebar()" />
                </div>
            </div>
            <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
        </div>
        <!-- <mat-form-field class="language hide-sm">
            <mat-label>{{'Language' | translate}}</mat-label>
            <mat-select name="select-clients" (selectionChange)="changeLang($event.value)" [value]="languageSelected">
                <mat-option value="en">English</mat-option>
                <mat-option value="ja">日本語</mat-option>
            </mat-select>
        </mat-form-field> -->
        <!-- <span class="date">{{date | date : "EEEE" | translate }}, {{date | date : "MMMM" | translate }} {{date |
            date : "d, y" | translate }}</span> -->
        <!-- <button mat-icon-button [matMenuTriggerFor]="notificationsMenu" (click)="handleMenuOpened()"
            class="notifications-menu-button" [class.has-new]="hasNew">
            <mat-icon>notifications</mat-icon>
        </button> -->
        <!-- <mat-menu #notificationsMenu="matMenu">
        <div class="notifications-top-nav-box">

            <div *ngIf="!notificationsLoaded" style="text-align: center;">
                <mat-spinner diameter="30" style="margin: auto;"></mat-spinner>
            </div>

            <div *ngIf="!notificationsList.length && notificationsLoaded">
                <p style="text-align: center;">No notifications yet</p>
            </div>

            <span *ngIf="notificationsList.length && notificationsLoaded">
                <div *ngFor="let item of notificationsList">
                    <button mat-menu-item [class.notification-badge]="item.viewed == false"
                        (click)="viewNotification(item)" style="font-size: 14px;">{{item.title}}</button>
                </div>
            </span>
        </div>
        <div>
            <button mat-menu-item
                style="width:35%; float: left; padding: 0px; margin-right: 0px; text-align: center;line-height: 35px; height: 35px;"
                (click)="moveToNotificationsPage()">View all</button>
            <button mat-menu-item color="primary"
                style="width:Calc(65% - 5px); padding: 0px; float: left; text-align: center; background-color: #32A5D7; color: #ffffff;line-height: 35px; height: 35px;"
                (click)="markAllAsRead()">Mark all as read</button>
        </div>
    </mat-menu> -->
        <!-- <button mat-icon-button [routerLink]="[]" title="{{'My Profile' |translate}}" [matMenuTriggerFor]="profileMenu">
            <mat-icon style="font-size: 40px;line-height: 40px;height: 40px;width: 40px;"
                *ngIf="!pictureLink || pictureLink === ''">account_circle</mat-icon>
            <img *ngIf="pictureLink && pictureLink !== ''" [src]="pictureLink"
                class="circle-img-btn z-depth-2 responsive-img activator">
        </button>
        <mat-menu #profileMenu="matMenu">
            <div class="profile-top-nav-box">
                <button mat-menu-item (click)="viewProfile()" style="font-size: 14px;">{{'Profile' | translate}}
                </button>
                <button mat-menu-item (click)="changeLanguage()" style="font-size: 14px;">
                    <ng-container *ngIf="languageSelected == 'en'">
                        Language/言語
                    </ng-container>
                    <ng-container *ngIf="languageSelected == 'ja'">
                        言語/Language
                    </ng-container>
                </button>
                <button mat-menu-item (click)="onLoggedout()" style="font-size: 14px;">{{'Logout' | translate}}
                </button>
            </div>
        </mat-menu> -->
        <!-- <div class="profile-menu" [matMenuTriggerFor]="profileMenu">

            <div class="dr-name">{{selectedUser.firstName}}</div>

            <button mat-icon-button>
                <mat-icon>expand_more</mat-icon>
            </button>
            <mat-menu #profileMenu="matMenu">
                <div class="profile-top-nav-box">
                    <button mat-menu-item (click)="viewProfile()" style="font-size: 14px;">Profile
                    </button>

                    <button mat-menu-item (click)="onLoggedout()" style="font-size: 14px;">Logout
                    </button>
                </div>
            </mat-menu>
        </div> -->
    </div>
</mat-toolbar>