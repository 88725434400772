import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { RequestService } from './request.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    public showNotificationUnRead: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public dnd: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public refreshData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private snackBar: MatSnackBar, private requestService: RequestService) { }

    public openSnackBar(message: string) {
        this.snackBar.open(message, '', {
            duration: 5000
        });
    }

    getNotifications(term: string, pageIndex: number, pageSize: number): Promise<void> {
        let filter: any = {
            "$and": [
                { "organizationId._id": { "$eq": this.requestService.orgId } },
                { "type": { "$nin": ['requestcamera', 'case'] } } // we exclude assign case and request camera
            ]
        };
        let currentUser = this.requestService.currentUser;
        let isAdmin = this.requestService.isUserRoleAdmin();
        let isInvestigator = this.requestService.isUserRoleInvestigator();
        if (currentUser) {
            if (isAdmin) {
                // nothing
            } else {
                if (isInvestigator) {
                    filter["$and"].push(
                        { "investigators._id": { "$in": [currentUser._id] } }
                    );
                } else {
                    if (currentUser['prefecture']) {
                        filter['$and'].push({ "prefecture": { "$eq": currentUser['prefecture'] } });
                    }
                    if (currentUser['region']) {
                        filter['$and'].push({ "region": { "$eq": currentUser['region'] } });
                    }
                    filter["$and"].push(
                        { "users": { "$size": 0 } }
                    );
                }
            }
        }

        return new Promise((resolve, reject) => {
            this.requestService.getDataList('notify', {
                term: term || '', termfields: ['message', 'user.name', 'createdName', 'location', 'case.name'], page: pageIndex + 1, perpage: pageSize, filter: filter, orderBy: 'updatedAt', orderDir: 'desc', fieldKeys: ['message', 'title', 'type', 'case', 'note', 'document', 'user', 'createdName', 'location', 'geolocation', 'updatedAt', 'investigators', 'investigatorgroup', 'description', 'createdAt']
            }, (data: any, error: any) => {
                if (data) {
                    resolve(data);
                }
                else if (error) {
                    reject(error);
                }
            });
        });
    }
}
