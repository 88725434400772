<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<div class="userInfo">
			<mat-form-field class="language hide-sm" style="width: 100%;">
				<mat-label>{{'Name' | translate}}</mat-label>
				<input matInput [required]="true" [(ngModel)]="collectionName" />
			</mat-form-field>
		</div>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
		<button mat-raised-button color="secondary"
			(click)="closeModal({action: 'create', name: collectionName})">{{'Confirm' |
			translate}}</button>
	</div>
</div>