<div class="kt-portlet">
	<div class="kt-portlet__head kt-portlet__head__custom" *ngIf="data.title">
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">{{data.title}}</h3>
		</div>
	</div>
	<div class="kt-form">
		<div class="kt-portlet__body">
			<div class="form-group kt-form__group row">
				<div class="col-lg-12">
					{{data.description}}
					<span *ngIf="data.description2"><br>{{data.description2}}</span>
					<div *ngIf="data.descriptionHtml" [innerHtml]="data.descriptionHtml | safeHtml">
					</div>
					<div *ngIf="data.descriptionArray" style="overflow-y: auto;max-height: 35vh;">
						<span *ngIf="data.description || data.description2"><br></span>
						<ul style="margin: 0px;padding-left: 20px;">
							<li *ngFor="let msg of data.descriptionArray"><span *ngIf="msg.title" style="font-size: 14px;">{{msg.title}} </span>{{msg.body}}</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
			<div class="kt-form__actions kt-form__actions--sm">
				<br>
				<button *ngIf="!data.btnTitle" mat-raised-button (click)="onNoClick()" style="float:right">{{'OK' | translate}}</button>
				<button *ngIf="data.btnTitle" mat-raised-button (click)="onNoClick()" style="float:right">{{data.btnTitle | translate}}</button>
			</div>
		</div>
	</div>
</div>
