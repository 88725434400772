<div class="col-xl-12 cropPopup">
	<h1 mat-dialog-title>{{ 'Plate Number Recognizer' | translate }}</h1>
	<div mat-dialog-content class="dialog-content">
		<span>{{'Upload an image then hit Go to get the recognized plate number' | translate}}</span>
		<ng-container *ngIf="isVideo">
			<div class="ImageTabContainer">
				<video *ngIf="videoLink && !uploadingVideo" controls autoplay width="640" height="360" type="video/mp4">
					<source [src]="videoLink" type="video/mp4">
					{{'Your browser does not support the video tag.' | translate}}
				</video>
				<span *ngIf="uploadingVideo">
					{{'Video is being proccessed please wait...' | translate}}
				</span>
			</div>
		</ng-container>
		<ng-container *ngIf="!isVideo">
			<ng-container *ngIf="!plateRecognitionResults">

				<div class="ImageTabContainer" *ngIf="!imageUrlPath">
					<img src="{{pictureLink}}">
				</div>
				<div *ngIf="imageUrlPath" style="text-align: center;">
					<!-- <image-cropper style="height:300px" [imageBase64]="imageUrlPath" [maintainAspectRatio]="false"
						[aspectRatio]="selectedRatio" format="jpg" [autoCrop]="false"
						(imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
						(cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper> -->
					<img [src]="imageUrlPath" style="max-width: 100%;" />
				</div>
			</ng-container>
		</ng-container>
		<div fxLayout="row wrap" fxLayoutGap="2vw" *ngIf="plateRecognitionResults" class="result-container">
			<div *ngIf="isEmpty" style="margin-top: 4vh;">
				{{ 'No records found' | translate}}
			</div>
			<ng-container *ngIf="plateRecognitionResults.length > 0">
				<div fxFlex="30" fxLayout="column" fxLayoutGap="1vw" fxLayoutAlign="space-between"
					*ngFor="let result of plateRecognitionResults; let index = index" class="plate-result">
					<!-- <mat-icon class="edit" (click)="edit(index)">edit</mat-icon> -->
					<div fxLayout="row" fxLayoutGap="1vw">
						<span fxFlex fxGrow="1">{{'Plate' | translate}}:</span>
						<span fxFlex *ngIf="!result.editable">{{result.display.plate || '-'}}</span>
						<div fxFlex *ngIf="result.editable">
							<mat-form-field>
								<input matInput [(ngModel)]="result.display.plate" />
							</mat-form-field>
						</div>
					</div>
					<!-- <div fxLayout="row" fxLayoutGap="1vw">
						<span fxFlex>{{'Region' | translate}}:</span><span fxFlex>{{result.display.region ||
							'-'}}</span>
					</div> -->
					<div fxLayout="row" fxLayoutGap="1vw">
						<span fxFlex fxGrow="1">{{'Type' | translate}}:</span>
						<span fxFlex class="capitalize" *ngIf="!result.editable">{{result.display.type
							|| '-'}}</span>
						<div fxFlex *ngIf="result.editable">
							<mat-form-field>
								<input matInput [(ngModel)]="result.display.type" />
							</mat-form-field>
						</div>
					</div>
					<div fxLayout="row" fxLayoutGap="1vw">
						<span fxFlex fxGrow="1">{{'Make' | translate}}:</span>
						<span fxFlex *ngIf="!result.editable" class="capitalize">{{result.display.make?.toLowerCase() ||
							'-'}}</span>
						<div fxFlex *ngIf="result.editable">
							<mat-form-field>
								<input matInput [(ngModel)]="result.display.make" />
							</mat-form-field>
						</div>
					</div>
					<div fxLayout="row" fxLayoutGap="1vw">
						<span fxFlex fxGrow="1">{{'Model' | translate}}:</span>
						<span fxFlex *ngIf="!result.editable" class="capitalize">{{result.display.model?.toLowerCase()
							||
							'-'}}</span>
						<div fxFlex *ngIf="result.editable">
							<mat-form-field>
								<input matInput [(ngModel)]="result.display.model" />
							</mat-form-field>
						</div>
					</div>
					<div fxLayout="row" fxLayoutGap="1vw">
						<span fxFlex fxGrow="1">{{'Color' | translate}}:</span>
						<span fxFlex *ngIf="!result.editable" class="capitalize">{{result.display.color?.toLowerCase()
							||
							'-'}}</span>
						<div fxFlex *ngIf="result.editable">
							<mat-form-field>
								<input matInput [(ngModel)]="result.display.color" />
							</mat-form-field>
						</div>
					</div>
					<div fxLayout="row" fxLayoutGap="1vw">
						<span fxFlex fxGrow="1">{{'Orientation' | translate}}:</span>
						<span fxFlex *ngIf="!result.editable"
							class="capitalize">{{result.display.orientation?.replace('_', ' ')?.toLowerCase() ||
							'-'}}</span>
						<div fxFlex *ngIf="result.editable">
							<mat-form-field>
								<input matInput [(ngModel)]="result.display.orientation" />
							</mat-form-field>
						</div>
					</div>
					<div fxLayout="row" fxLayoutGap="1vw">
						<mat-form-field style="width: 100%;">
							<mat-label>{{'Camera ID' | translate}}</mat-label>
							<mat-select [(ngModel)]="selectedCamera">
								<mat-option value=""></mat-option>
								<mat-option *ngFor="let camera of data.cameras"
									[value]="camera._id">{{camera.camera_id}}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<button mat-raised-button [disabled]="loading"
						(click)="addToDatabase(result.originalResult, index)">
						{{ "Add to Database" | translate }}
					</button>
				</div>
			</ng-container>
		</div>
	</div>
	<div mat-dialog-actions class="dialog-actions" align="end">
		<div style="width: 100%;margin-top:10px;">
			<button mat-raised-button (click)="continueVideoUpload()"
				[disabled]="!videoFileData || loading || uploadingVideo" *ngIf="isVideo && videoLink">{{'Go' |
				translate}}</button>
			<button mat-raised-button (click)="recognize()" *ngIf="!isVideo && imageUrlPath && imageSelected"
				[disabled]="loading">{{'Go' | translate}}</button>
			<input #imageInput [hidden]="true" type="file" name="upfileLogo" accept="image/*,.mp4" multiple="false"
				id="image_selector" (change)="onFileSelected($event)">
			<button mat-raised-button (click)="imageInput.click()" [disabled]="loading">{{'Select' |
				translate}}</button>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<!-- <button mat-raised-button (click)="clearIt($event)" *ngIf="imageUrlPath && imageSelected"
				[disabled]="loading">{{'Clear' | translate}}</button> -->
		</div>
	</div>
</div>