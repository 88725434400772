<!-- <select id="{{dataType}}" class="form-control" [disabled]="loading" (change)="setAttribute($event.target.value)">
  <option value="" [selected]="value === undefined">{{placeholder | translate}}</option>
  <option value="{{item._id}}" *ngFor="let item of selectData" [selected]="value === item._id">{{item.name}}</option>
</select> -->
<form #dataForm="ngForm" (ngSubmit)="onSubmit()">
  <div *ngIf="!hideBecauseOne">
    <div *ngIf="apiTarget !== 'search'">
      <mat-form-field>
        <!-- <input [style.visibility]="'hidden'" matInput name="{{itemName}}" [(ngModel)]="value" [required]="required" [errorStateMatcher]="esMatcher" > -->
        <mat-label>{{placeholder | translate}}</mat-label>
        <mat-select ng-model="itemName" [required]="required" (selectionChange)="setAttribute($event)" [value]="value"
          multiple [disabled]="conditionalDisable">
          <mat-option *ngFor="let itm of selectData" [value]="itm._id">
            <span *ngFor="let itmDn of displayName">{{itm[itmDn]}} </span>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="required && (value === undefined || value.length === 0)">
          {{placeholder | translate}} {{'is required' | translate}}
        </mat-error>
      </mat-form-field>
      <img *ngIf="loading"
        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
    </div>
    <div *ngIf="apiTarget === 'search'" class="searchClassMultiple">
      <ng-select class="custom"
        [class.ng-error]="isSubmitted && required && (value === undefined || value.length === 0)" ng-model="itemName"
        name="{{itemName}}" [items]="filteredData | async" [loading]="loading" bindLabel="text" bindValue="_id"
        [multiple]="true" [closeOnSelect]="false" [clearable]="clearable" (search)="termSearch($event)"
        (clear)="termSearch('')" (change)="setAttribute($event)" [(ngModel)]="value" [disabled]="conditionalDisable"
        placeholder="{{placeholder | translate}}{{required ? '*' : ''}}" (blur)="termSearch('')"
        dropdownPosition="bottom">
        <!-- <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <input id="item-{{index}}" type="checkbox" [value]="item$.selected" /> {{item.text}}
        </ng-template> -->
        <ng-template ng-notfound-tmp>
          {{ 'ng-select.noItemsFound' | translate }}
        </ng-template>

        <ng-template ng-loadingtext-tmp>
          {{ 'ng-select.loading' | translate }}
        </ng-template>
      </ng-select>
      <mat-error class="mat-mdc-form-field-subscript-wrapper"
        *ngIf="isSubmitted && !customError && required && (value === undefined || value.length === 0)">
        {{placeholder | translate}} {{'is required' | translate}}
      </mat-error>
      <mat-error [class.customError]="!customError.showError" *ngIf="isSubmitted && customError && customError.show"
        [class.mat-mdc-form-field-subscript-wrapper]="customError.showError">
        {{customError.message | translate}}
      </mat-error>
    </div>
  </div>
</form>