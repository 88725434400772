<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<div class="userInfo">
			<mat-form-field class="language hide-sm">
				<mat-label>{{'Language' | translate}}</mat-label>
				<mat-select name="select-clients" (selectionChange)="changeLang($event.value)"
					[value]="languageSelected">
					<mat-option value="en">English</mat-option>
					<mat-option value="ja">日本語</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
	</div>
</div>