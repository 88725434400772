import { Component, OnInit, ViewChild, ChangeDetectorRef, HostListener, ElementRef, TemplateRef, Input, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { LayoutUtilsService, RequestService, StoreService } from '../../../shared/services';
import { CustomSelectAutocompleteComponent } from '../../../shared/components/custom-select-autocomplete/custom-select-autocomplete.component';
import { ModalUserViewDialogComponent } from '../custom-user-view-dialog/custom-user-view-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { FirebaseHandlersService } from '../../services/firebase-handlers.service';
import { VonageService } from '../../services/vonage.service';
import { ModalLanguageDialogComponent } from '../custom-language-dialog/custom-language-dialog.component';
import { NotificationService } from '../../services/notification.service';
import { AblyService } from '../../services/ably.service';
import { CaseService } from '../../services/case.service';
import { EnumPipe } from '../../pipes';
import { ChatService } from '../../services/chat.service';


@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public languageSelected: string = 'en';
  public title = environment.serverName;
  public subTitle = environment.subServerName;
  public pushRightClass: string = 'push-right';
  public selectedOrganization: string = '';
  public userType: string = 'default';
  public dataType: string = 'organization';
  public dataTypeDisplay: string = 'Organization';
  public selectedDatafilters: any = undefined;
  public hasNew: boolean = false;
  public pageSize = 10;
  // public joinCallInvite: any = {};
  // public currentNotification: any = undefined;
  // public notificationTimer: any = undefined;
  // public notificationAudio: any = undefined;
  // public newCase: any = undefined;
  // public notificationTimeout: any = undefined;

  date: Date;
  screenWidth: number;
  public pictureLink: string = 'assets/images/profile.png';
  isDnd: boolean = true;

  @ViewChild('customselectautocomplete') customselectautocomplete: CustomSelectAutocompleteComponent;
  // @ViewChild('notification') set playerRef(ref: ElementRef<HTMLAudioElement>) {
  //   if (ref)
  //     this.notificationAudio = ref.nativeElement;
  // }
  @Input() headerTemplate: TemplateRef<any>;
  constructor(private requestService: RequestService, public router: Router, private translate: TranslateService,
    public dialog: MatDialog, private layoutUtilsService: LayoutUtilsService, private firebaseHandler: FirebaseHandlersService, private vonageService: VonageService, private notificationService: NotificationService, private ablyService: AblyService, private caseService: CaseService, private enumPipe: EnumPipe, private chatService: ChatService) {
    if (localStorage.getItem('languageSelected')) {
      this.languageSelected = localStorage.getItem('languageSelected');
    }
    this.userType = this.requestService.getUserType();
  }

  ngOnDestroy(): void {
    // this.ablyService.unsubscribe('new-case', 'global');
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.date = new Date();
    this.pushRightClass = 'push-right';
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.pictureLink = data.pictureLink;
        }
      })
    );
    this.subscriptions.push(
      this.requestService.pageOrganization.subscribe((data) => {
        if (data) {
          this.selectedOrganization = data._id;
        }
      })
    );
    this.subscriptions.push(
      this.requestService.currentPageTitle.subscribe((data) => {
        if (data) {
          this.subTitle = data;
        }
      })
    );

    // this.subscriptions.push(this.notificationService.dnd.subscribe((isDnd) => {
    //   this.isDnd = isDnd;
    // }));

    // this.subscriptions.push(this.ablyService.isConnected.subscribe(isConnected => {
    //   if (isConnected) {
    //     this.ablyService.subscribe('new-case', 'global', (message) => {
    //       this.caseService.setNewCaseSubject.next(message);
    //     });
    //     // this.ablyService.publish('new-case', JSON.stringify({ send: true }), 'global', (message) => {
    //     //   console.log(message)
    //     // });
    //   }
    // }));

    // this.subscriptions.push(this.caseService.setNewCaseSubject.subscribe(profile => {
    //   if (profile) {
    //     this.newCase = profile;
    //     // const typeEnum = this.caseService.caseMetadata.fields?.find(i => i.name == 'case_type')?.enum;
    //     // this.newCase.case_type = this.enumPipe.transform(profile.case_type, typeEnum);
    //     clearTimeout(this.notificationTimeout);
    //     this.notificationTimeout = setTimeout(() => {
    //       this.newCase = undefined;
    //     }, 2 * 60 * 1000);
    //   }
    // }));

    // this.subscriptions.push(this.firebaseHandler.currentMessage.subscribe(message => {
    //   if (message?.data.senderId != this.selectedUser?._id) {
    //     if (message?.notification) {
    //       // this.notificationService.showNotificationUnRead.next(true);
    //       // this.notificationService.refreshData.next(true);
    //       // console.log(message)
    //       if (!this.isDnd) {
    //         if (this.notificationAudio) {
    //           this.notificationAudio.load();
    //           this.notificationAudio.loop = 0;
    //           this.notificationAudio.volume = 0.25;
    //           this.notificationAudio.play().catch((error: any) => { });
    //         }

    //         this.currentNotification = message.notification.body;
    //         if (message.data.invitedBy) {
    //           this.joinCallInvite = message.data;
    //         }
    //         if (this.notificationTimer) {
    //           clearTimeout(this.notificationTimer);
    //           this.notificationTimer = undefined;
    //         }
    //         this.notificationTimer = setTimeout(() => this.currentNotification = null, 10000);
    //       }
    //     }
    //     else
    //       this.currentNotification = null;
    //   }
    // }));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }


  // setSelectedOrganization(e, reload = true) {
  //   this.requestService.orgId = "5e5e69dd2023950162466258";
  //   this.storeService.set('orgId', '5e5e69dd2023950162466258');
  // }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  onLoggedout() {
    // this.requestService.logout();
    this.firebaseHandler.logout();
  }

  changeLang(language: string) {
    localStorage.setItem('languageSelected', language);
    this.languageSelected = language;
    this.translate.use(language);
    location.reload();
  }

  formatDate(seconds) {


    let output = new Date(seconds * 1000);

    return moment(output).format("YYYY-MM-DD HH:mm");


  }

  viewProfile() {
    const dialogRef = this.dialog.open(ModalUserViewDialogComponent, {
      width: '850px',
      data: {
        dataType: 'user',
        title: this.translate.instant('My Profile'),
        profile: true,
        data: this.selectedUser,
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('result', result);
      }
    });
  }
  changeLanguage() {
    const dialogRef = this.dialog.open(ModalLanguageDialogComponent, {
      width: '500px',
      data: {
        title: this.translate.instant('Language'),
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('result', result);
      }
    });
  }

  /**
   * @description - remove the red dot on the notifications if the menu is opened
   */
  public handleMenuOpened() {
    if (this.hasNew) this.hasNew = !this.hasNew;
  }
  public goHome() {
    this.router.navigate(['/cases/list']);
  }

  // closeNotification() {
  //   clearTimeout(this.notificationTimer);
  //   this.currentNotification = null;
  // }

  // joinMeeting(invited) {
  //   this.chatService.showCallDialogSubject.next({
  //     show: true,
  //     caseId: invited.caseId,
  //     invitedBy: invited.invitedBy,
  //     // investigators: invited.investigators,
  //   });
  // }
}
