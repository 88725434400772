<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<div class="userInfo">
			<!-- <app-custom-select [value]="caseSelected" [dataType]="'case'" [apiTarget]="'searchObject'"
				[placeholder]="'Link to case'" (onSelectReturn)="selectCase($event)"></app-custom-select> -->
			<mat-form-field class="example-full-width">
				<mat-label>{{'Cases' | translate}}</mat-label>
				<mat-select [(ngModel)]="caseSelected" placeholder="{{'Link to case' | translate}}">
					<input #searchInput class="inputSearchStyle" type="text" placeholder="{{'Search' | translate}}"
						(keyup)="onKey($event.target.value)" [value]="searchVal">
					<mat-option *ngFor="let itm of availableCases" [value]="itm._id"
						[disabled]="assignedCases[itm._id]">{{itm.case_id}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
		<button mat-raised-button (click)="closeModal({action: 'assign', case: caseSelected})"
			[disabled]="!this.caseSelected || this.caseSelected === ''">{{'Assign'
			|
			translate}}</button>
	</div>
</div>