import { Injectable } from "@angular/core";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { environment } from "src/environments/environment";

@Injectable()
export class DateLocaleProvider {
    getLocale(): string {
        let dynamicLocale = environment.lang;
        if (localStorage.getItem('languageSelected')) {
            let languageSelected = localStorage.getItem('languageSelected');
            dynamicLocale = languageSelected;
        }
        return dynamicLocale;
    }
}
export const MAT_DATE_LOCALE_PROVIDER = {
    provide: MAT_DATE_LOCALE,
    useFactory: (dateLocaleProvider: DateLocaleProvider) => dateLocaleProvider.getLocale(),
    deps: [DateLocaleProvider],
};
