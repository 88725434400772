<div class="col-xl-12" *ngIf="selectedEvent">
	<h1 mat-dialog-title style="margin: 0px;">{{selectedEvent.name}}</h1>
	<div mat-dialog-content>
		<div class="userInfo">
			<mat-card>
				<mat-card-content>
					<ng-template #simpleText>
						<span class="eventType">
							<app-enum-view [enumValue]="selectedEvent.eventType"
								[enumList]="metaFieldSetting['eventType'].enum"></app-enum-view>
						</span>
						<mat-list>
							<mat-list-item>{{selectedEvent.description}}</mat-list-item>
							<mat-list-item>
								<span>{{metaFieldSetting['startdate'].displayName | translate}}:</span>
								{{selectedEvent.startdate | date: dateFormat +' hh:mm aa'}}
							</mat-list-item>
							<mat-list-item>
								<span>{{metaFieldSetting['enddate'].displayName | translate}}:</span>
								{{selectedEvent.enddate | date: dateFormat +' hh:mm aa'}}
							</mat-list-item>
						</mat-list>
					</ng-template>
					<ng-container [ngTemplateOutlet]="contentTemplate || simpleText"
						[ngTemplateOutletContext]="{ contentTemplateData: selectedEvent }">
					</ng-container>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div mat-dialog-actions class="bottomBtns">
		<button mat-raised-button color="danger" (click)="closeModal(undefined)" cdkFocusInitial>{{'Close' |
			translate}}</button>
		<button *ngIf="canEdit" mat-raised-button (click)="closeModal('edit')">{{'Edit' | translate}}</button>
		<button *ngIf="canDelete" mat-raised-button (click)="closeModal('delete')">{{'Delete' | translate}}</button>
		<button *ngIf="canCreate" mat-raised-button (click)="closeModal('create')">{{'Create Event' |
			translate}}</button>
		<button *ngIf="canOpen && selectedEvent.meetingType !== '2'" mat-raised-button
			(click)="closeModal('open')">{{'Open Event' | translate}}</button>
		<button *ngFor="let itm of extraButtons" mat-raised-button (click)="closeModal(itm.action)" title="{{itm.title |
			translate}}">{{itm.name |
			translate}}</button>
		<button *ngIf="showAddToPersonalCalendar" mat-raised-button [matMenuTriggerFor]="menuCalender"
			#menuTrigger="matMenuTrigger" (click)="cancelThru($event)"
			title="{{'Add to my personal calendar' | translate}}" disableRipple="true" style="position: static;">
			{{'Add to my personal calendar' | translate}}
		</button>
		<mat-menu #menuCalender="matMenu">
			<button mat-menu-item (click)="addtoMyCalender($event, 'download')" class="iconCalender">
				<img src="/assets/images/icon/microsoft-outlook.png" />
				<span>{{'Outlook' | translate}}</span>
			</button>
			<button mat-menu-item (click)="addtoMyCalender($event, 'outlook')" class="iconCalender">
				<img src="/assets/images/icon/icon-outlookcom-t5.svg" />
				<span>{{'Outlook Web' | translate}}</span>
			</button>
			<button mat-menu-item (click)="addtoMyCalender($event, 'google')" class="iconCalender">
				<img src="/assets/images/icon/icon-google-t5.svg" />
				<span>{{'Google' | translate}}</span>
			</button>
			<button mat-menu-item (click)="addtoMyCalender($event, 'yahoo')" class="iconCalender">
				<img src="/assets/images/icon/icon-yahoo-t5.svg" />
				<span>{{'Yahoo!' | translate}}</span>
			</button>
			<button mat-menu-item (click)="addtoMyCalender($event, 'download')" class="iconCalender">
				<img src="/assets/images/icon/icon-apple-t5.svg" />
				<span>{{'Apple' | translate}}</span>
			</button>
		</mat-menu>
	</div>
	<br />
</div>