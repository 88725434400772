import { Injectable } from '@angular/core';
import { Auth, signInWithCustomToken, signOut, onAuthStateChanged, User } from '@angular/fire/auth';
import { Observable } from 'rxjs';
// import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FirebaseAuthService {
    user$: Observable<User | null>;

    constructor(private auth: Auth) {
        this.user$ = new Observable<User | null>((subscriber) => {
            return onAuthStateChanged(this.auth, (user) => subscriber.next(user));
        });
    }

    async signInWithCustomToken(customToken: string): Promise<void> {
        await signInWithCustomToken(this.auth, customToken);
    }

    async getIdToken(): Promise<string | null> {
        const user = this.auth.currentUser;
        return user ? await user.getIdToken() : null;
    }

    signOut(): Promise<void> {
        return signOut(this.auth);
    }
}