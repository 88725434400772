<div class="col-xl-12">
	<div mat-dialog-title class="dialog-title">
		<h2>{{data.title}}</h2>
	</div>
	<div mat-dialog-content class="dialog-content">
		<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="10px">
			<div fxFlex>
				<mat-form-field>
					<mat-label>{{metaFieldSetting['startdate'].displayName | translate}} {{'Date' | translate}}
					</mat-label>
					<input matInput name="'startdate'" [(ngModel)]="selectedStartDate" ng-model="col.name"
						[matDatepicker]="pickerStart"
						placeholder="{{metaFieldSetting['startdate'].displayName| translate}}"
						(dateChange)="selectedStartDate = $event.value">
					<mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
					<mat-datepicker #pickerStart></mat-datepicker>
				</mat-form-field>
			</div>
			<div fxFlex>
				<mat-form-field>
					<mat-label>{{metaFieldSetting['enddate'].displayName | translate}} {{'Date' | translate}}
					</mat-label>
					<input matInput name="'enddate'" [(ngModel)]="selectedEndDate" ng-model="col.name"
						[matDatepicker]="pickerEnd" placeholder="{{metaFieldSetting['enddate'].displayName| translate}}"
						(dateChange)="selectedEndDate = $event.value">
					<mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
					<mat-datepicker #pickerEnd></mat-datepicker>
				</mat-form-field>
			</div>
		</div>
		<ng-container *ngIf="clients">
			<div>
				<mat-form-field *ngIf="clients">
					<mat-label>{{'Select clients' | translate}}</mat-label>
					<mat-select name="select-clients" (selectionChange)="selectedClient = $event.value"
						[(ngModel)]="selectedClient">
						<mat-option value="all">{{'All Clients' | translate}}</mat-option>
						<ng-container *ngFor="let itm of users">
							<mat-option [value]="itm._id">{{itm.name}}</mat-option>
						</ng-container>
					</mat-select>
				</mat-form-field>
			</div>
			<div>
				<mat-form-field>
					<mat-label>{{'Select' | translate}} {{metaFieldSetting['category'].displayName | translate}}
					</mat-label>
					<mat-select name="'category'" (selectionChange)="selectedCategory = $event.value"
						[(ngModel)]="selectedCategory">
						<mat-option [value]="'all'">
							{{'All' | translate}} {{metaFieldSetting['category'].displayName | translate}}</mat-option>
						<ng-container *ngFor="let itm of metaFieldSetting['category'].enum">
							<mat-option [value]="itm.value">
								{{itm.displayName | translate}}</mat-option>
						</ng-container>
					</mat-select>
				</mat-form-field>
			</div>
			<div>
				<mat-form-field>
					<mat-label>{{'Select' | translate}} {{metaFieldSetting['subcategory'].displayName | translate}}
					</mat-label>
					<mat-select name="'subcategory'"
						(selectionChange)="selectedSubCategory = $event.value;buildSetting()"
						[(ngModel)]="selectedSubCategory">
						<mat-option [value]="'all'">
							{{'All' | translate}} {{metaFieldSetting['subcategory'].displayName | translate}}
						</mat-option>
						<ng-container *ngFor="let itm of metaFieldSetting['subcategory'].enum">
							<mat-option [value]="itm.value">
								{{itm.displayName | translate}}</mat-option>
						</ng-container>
					</mat-select>
				</mat-form-field>
			</div>
			<div>
				<mat-form-field>
					<mat-label>{{'Select' | translate}} {{metaFieldSetting['facility'].displayName | translate}}
					</mat-label>
					<mat-select name="'facility'" (selectionChange)="selectedFacility = $event.value"
						[disabled]="metaFieldSetting['facility'].disabled" [(ngModel)]="selectedFacility">
						<mat-option [value]="'all'">
							{{'All' | translate}} {{metaFieldSetting['facility'].displayName | translate}}</mat-option>
						<ng-container *ngFor="let itm of metaFieldSetting['facility'].enum">
							<mat-option [value]="itm.value">
								{{itm.displayName | translate}}</mat-option>
						</ng-container>
					</mat-select>
				</mat-form-field>
			</div>
			<div>
				<mat-form-field>
					<mat-label>{{'Select' | translate}} {{metaFieldSetting['safe'].displayName | translate}}</mat-label>
					<mat-select name="'safe'" (selectionChange)="selectSAFE($event.value)" [value]="selectedSAFE"
						multiple>
						<!-- <div class="allSelection" (click)="selectSAFE(['all'])">{{'All' | translate}}</div> -->
						<mat-option class="hide-checkbox" [value]="'all'">
							{{'All' | translate}} {{metaFieldSetting['safe'].displayName | translate}}</mat-option>
						<ng-container *ngFor="let itm of metaFieldSetting['safe'].enum">
							<mat-option [value]="itm.value">
								{{itm.displayName | translate}}</mat-option>
						</ng-container>
					</mat-select>
				</mat-form-field>
			</div>
			<div>
				<mat-form-field>
					<mat-label>{{'Select' | translate}} {{metaFieldSetting['wrna'].displayName | translate}}</mat-label>
					<mat-select name="'wrna'" (selectionChange)="selectWrna($event.value)" [value]="selectedWrna"
						multiple>
						<!-- <div class="allSelection" (click)="selectWrna(['all'])">{{'All' | translate}}</div> -->
						<mat-option class="hide-checkbox" [value]="'all'">
							{{'All' | translate}} {{metaFieldSetting['wrna'].displayName | translate}}</mat-option>
						<ng-container *ngFor="let itm of metaFieldSetting['wrna'].enum">
							<mat-option [value]="itm.value">
								{{itm.displayName | translate}}</mat-option>
						</ng-container>
					</mat-select>
				</mat-form-field>
			</div>
		</ng-container>
	</div>
	<div mat-dialog-actions class="dialog-actions">
		<span>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">Close</button>
			<button mat-raised-button (click)="downloadData()">Export</button>
		</span>
	</div>
	<br />
</div>