<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<div *ngIf="data.modalSetting">
			<mat-card *ngIf="data.modalSetting.fields">
				<!-- <mat-card-header>
					<mat-card-title>{{data.type}}</mat-card-title>
				</mat-card-header> -->
				<mat-card-content *ngIf="data.data">
					<ng-container *ngFor="let col of data.modalSetting.fields">
						<div *ngIf="col.visible" class="fieldItem">
							<div *ngIf="col.type !== 'action' && (col.editable || !col.generated)">
								<div class="fieldInput" *ngIf="col.type === 'tags'">
									<div style="font-weight:bold;margin-bottom: 5px;">{{col.displayName | translate}}:
									</div>
									<mat-chip-listbox aria-label="Tags">
										<mat-chip-option *ngFor="let tg of data.data[col.name]">{{tg.name}}
										</mat-chip-option>
									</mat-chip-listbox>
								</div>
								<div class="fieldInput" *ngIf="col.type === 'reference'">
									<span style="font-weight:bold">{{col.displayName | translate}}: </span> <span
										*ngIf="data.data.hasOwnProperty(col.name)">{{data.data[col.name]['name']}}</span>
								</div>
								<div class="fieldInput" *ngIf="col.type === 'enum'">
									<span style="font-weight:bold">{{col.displayName | translate}}: </span>
									<app-enum-view [enumValue]="data.data[col.name]" [enumList]="col.enum">
									</app-enum-view>
								</div>
								<div class="fieldInput" *ngIf="col.type === 'lnglat'">
									<app-google-map #currentDialogMap [height]="'250px'" [addMyMarker]="false"
										[setGeoLocation]="data.data[col.name]">
									</app-google-map>
								</div>
								<div class="fieldInput"
									*ngIf="col.type !== 'enum' && col.type !== 'tags' && col.type !== 'reference'&& col.type !== 'lnglat'">
									<span style="font-weight:bold">{{col.displayName | translate}}:
									</span>{{data.data[col.name]}}
								</div>
							</div>
						</div>
					</ng-container>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div mat-dialog-actions>
		<span *ngIf="data.modalSetting && !data.modalSetting.hasOwnProperty('footer')">
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">Close</button>
		</span>
	</div>
</div>