import { Directive, ElementRef, Renderer2, Input } from '@angular/core';
// const inputs = [
//     'fxLayout', 'fxLayout.xs', 'fxLayout.sm', 'fxLayout.md',
//     'fxLayout.lg', 'fxLayout.xl', 'fxLayout.lt-sm', 'fxLayout.lt-md',
//     'fxLayout.lt-lg', 'fxLayout.lt-xl', 'fxLayout.gt-xs', 'fxLayout.gt-sm',
//     'fxLayout.gt-md', 'fxLayout.gt-lg'
// ];
// const selector = `
//     [fxLayout], [fxLayout.xs], [fxLayout.sm], [fxLayout.md],
//     [fxLayout.lg], [fxLayout.xl], [fxLayout.lt-sm], [fxLayout.lt-md],
//     [fxLayout.lt-lg], [fxLayout.lt-xl], [fxLayout.gt-xs], [fxLayout.gt-sm],
//     [fxLayout.gt-md], [fxLayout.gt-lg]
//   `;
// @Directive({ selector, inputs })

// @Directive({
//     selector: '[fxFlex]'
// })
const inputs = [
    'fxFlex', 'fxLayout', 'fxFlexFill'
];
const selector = `
    [fxFlex], [fxLayout], [fxFlexFill]
  `;
@Directive({ selector, inputs })
export class MyFlexLayoutDirective {
    public flexDirection: string | undefined;
    public flexFlow: string | undefined;
    @Input() fxFlexFill: string | undefined;
    @Input() fxFlex: string | undefined;
    @Input() fxLayout: string | undefined;
    @Input('fxLayout.sm') fxLayoutSm: string | undefined;
    @Input('fxLayout.md') fxLayoutMd: string | undefined;
    @Input('fxLayout.lg') fxLayoutLg: string | undefined;
    @Input('fxLayout.lt-sm') fxLayoutLtSm: string | undefined;
    @Input('fxLayout.lt-md') fxLayoutLtMd: string | undefined;
    @Input('fxLayout.lt-lg') fxLayoutLtLg: string | undefined;
    @Input('fxLayout.gt-sm') fxLayoutGtSm: string | undefined;
    @Input('fxLayout.gt-md') fxLayoutGtMd: string | undefined;
    @Input('fxLayout.gt-lg') fxLayoutGtLg: string | undefined;
    @Input() fxLayoutAlign: string | undefined;
    @Input() fxLayoutGap: string | undefined;
    @Input() fxGrow: string | undefined;
    @Input() debugIt: boolean = false;
    constructor(private el: ElementRef, private renderer: Renderer2) {

    }
    ngOnInit() {
        if (this.fxLayoutGap) {
            this.renderer.setAttribute(this.el.nativeElement, 'ng-reflect-fx-gap', this.fxLayoutGap);
        }
        if (this.fxLayoutAlign) {
            this.renderer.setAttribute(this.el.nativeElement, 'ng-reflect-fx-align', this.fxLayoutAlign);
        }
        if (this.fxGrow) {
            this.renderer.setAttribute(this.el.nativeElement, 'ng-reflect-fx-grow', this.fxGrow);
        }
        if (this.fxLayout) {
            this.renderer.setAttribute(this.el.nativeElement, 'ng-reflect-fx-layout', this.fxLayout);
        }
        if (this.fxFlex) {
            this.renderer.setAttribute(this.el.nativeElement, 'ng-reflect-fx-flex', this.fxFlex);
        }
        if (this.el.nativeElement.hasAttribute('fxFlexFill')) {
            this.renderer.setStyle(this.el.nativeElement, 'height', '100%');
            this.renderer.setStyle(this.el.nativeElement, 'min-height', '100%');
            this.renderer.setStyle(this.el.nativeElement, 'min-width', '100%');
            this.renderer.setStyle(this.el.nativeElement, 'width', '100%');
        }
        if (this.el.nativeElement.hasAttribute('fxFlex') || this.el.nativeElement.hasAttribute('fxLayout')) {
            this.applyFlexStyles();
        }
    }
    ngAfterViewChecked() {
        if (this.el.nativeElement.hasAttribute('fxFlex') || this.el.nativeElement.hasAttribute('fxLayout')) {
            this.ApplyfxLayoutGap();
            this.ApplyfxLayoutFlex();
        }
    }
    private applyFlexStyles(): void {
        this.flexDirection = this.getFlowDirection(this.fxLayout);
        this.flexFlow = this.getFlow(this.fxLayout);
        // Determine direction based on all fxLayout
        // this.determineDirection();
        // Determine main axis based on fxLayout
        // const justifyContent = this.getJustifyContent();

        // Determine cross axis based on fxLayoutAlign
        const alignItems = this.getAlignItems();
        const placeContent = this.getPlaceContent();
        // console.log('fxFlex', this.fxFlex);
        // console.log('fxLayout', this.fxLayout);
        // Apply styles
        if (this.fxLayout) {
            this.renderer.setStyle(this.el.nativeElement, 'display', 'flex');
            this.renderer.setStyle(this.el.nativeElement, 'flex-direction', this.fxLayout);
            // this.renderer.setStyle(this.el.nativeElement, 'justify-content', justifyContent);
            this.updateLayoutDirection();
        }
        window.addEventListener('resize', () => {
            if (this.fxLayout) {
                this.updateLayoutDirection();
            }
            this.ApplyfxLayoutGap();
            this.ApplyfxLayoutFlex();
        });

        if (placeContent) {
            this.renderer.setStyle(this.el.nativeElement, 'display', 'flex');
            let alignItemsData = 'stretch';
            if (alignItems) {
                if (alignItems === 'center') {
                    alignItemsData = 'center';
                } else if (alignItems === 'end') {
                    alignItemsData = 'flex-end';
                }
            }
            if (placeContent === 'end') {
                this.renderer.setStyle(this.el.nativeElement, 'place-content', alignItemsData + ' flex-end');
                this.renderer.setStyle(this.el.nativeElement, 'align-items', 'stretch');
            } else if (placeContent === 'start') {
                this.renderer.setStyle(this.el.nativeElement, 'place-content', alignItemsData + ' flex-start');
                this.renderer.setStyle(this.el.nativeElement, 'align-items', 'flex-start');
            } else if (placeContent === 'space-between') {
                this.renderer.setStyle(this.el.nativeElement, 'place-content', alignItemsData + ' space-between');
                this.renderer.setStyle(this.el.nativeElement, 'align-items', 'stretch');
            } else {
                this.renderer.setStyle(this.el.nativeElement, 'place-content', alignItemsData + ' center');
                this.renderer.setStyle(this.el.nativeElement, 'align-items', 'stretch');
            }
        }
        if (alignItems) {
            if (alignItems === 'end') {
                this.renderer.setStyle(this.el.nativeElement, 'align-items', 'flex-end');
            } else if (alignItems === 'start') {
                this.renderer.setStyle(this.el.nativeElement, 'align-items', 'flex-start');
            } else if (alignItems === 'space-between') {
                this.renderer.setStyle(this.el.nativeElement, 'align-items', 'stretch');
            } else if (alignItems === 'center') {
                this.renderer.setStyle(this.el.nativeElement, 'align-items', 'center');
            } else {
                this.renderer.setStyle(this.el.nativeElement, 'align-items', 'stretch');
            }
        }
        this.renderer.setStyle(this.el.nativeElement, 'box-sizing', 'border-box');

        let fxGrowData = '1';
        let fxBasisData = '0%';
        if (this.fxGrow !== undefined) {
            // this.renderer.setStyle(this.el.nativeElement, 'flex-grow', this.fxGrow);
            fxGrowData = this.fxGrow;
        }
        if (this.fxFlex) {
            const siblingBefore = this.el.nativeElement.previousElementSibling;
            const siblingAfter = this.el.nativeElement.nextElementSibling;
            if (!siblingBefore || !siblingAfter) {
                fxBasisData = '100%';
            }
            this.renderer.setStyle(this.el.nativeElement, 'flex', fxGrowData + ' 1 ' + fxBasisData);
        } else {
            this.renderer.setStyle(this.el.nativeElement, 'flex', fxGrowData + ' 1 ' + fxBasisData);
        }
        this.ApplyfxLayoutGap();
        this.ApplyfxLayoutFlex();
        // setTimeout(() => {
        //     this.ApplyfxLayoutGap();
        //     this.ApplyfxLayoutFlex();
        // }, 1000);
    }

    // private determineDirection() {
    //     if (this.fxLayout || this.fxLayoutXs || this.fxLayoutSm || this.fxLayoutMd || this.fxLayoutLg || this.fxLayoutXl || this.fxLayoutLtSm || this.fxLayoutLtMd || this.fxLayoutLtLg || this.fxLayoutLtXl || this.fxLayoutGtXs || this.fxLayoutGtSm || this.fxLayoutGtMd || this.fxLayoutGtLg) {
    //         this.flexDirection = this.fxLayout;
    //     }
    // }
    private getJustifyContent(): string {
        // Logic to determine justify-content based on fxLayout
        // You can customize this based on your needs
        // Example: return 'space-between' if fxLayout is 'row'
        return 'center'; // Default value
    }

    private getPlaceContent(): string | undefined {
        let placeContent = undefined;
        if (this.fxLayoutAlign) {
            let layoutValues = this.fxLayoutAlign.split(' ');
            placeContent = layoutValues[0] || undefined;
        }
        return placeContent; // Default value
    }
    private getAlignItems(): string | undefined {
        let alignItem = undefined;
        if (this.fxLayoutAlign) {
            let layoutValues = this.fxLayoutAlign.split(' ');
            alignItem = layoutValues[1] || undefined;
        }
        return alignItem; // Default value
    }
    private getFlowDirection(fxLayout): string | undefined {
        let flowDirection = undefined;
        if (fxLayout) {
            let layoutValues = fxLayout.split(' ');
            flowDirection = layoutValues[0] || undefined;
        }
        return flowDirection; // Default value
    }
    private getFlow(fxLayout): string | undefined {
        let flowItem = undefined;
        if (fxLayout) {
            let layoutValues = fxLayout.split(' ');
            flowItem = layoutValues[1] || undefined;
        }
        return flowItem; // Default value
    }
    private ApplyfxLayoutGap() {
        const parent = this.el.nativeElement.parentElement;
        if (parent) {
            if (parent.hasAttribute('ng-reflect-fx-gap')) {
                let flexGap = parent.getAttribute('ng-reflect-fx-gap');
                if (flexGap) {
                    let fxLayout = undefined;// ng-reflect-fx-layout="column"
                    let flexDirection = 'column';// ng-reflect-fx-layout="column"
                    // if (parent.style.hasOwnProperty('flex-direction')) {
                    //     flexDirection = parent.style['flex-direction'];
                    // }
                    if (parent.hasAttribute('ng-reflect-fx-layout')) {
                        fxLayout = parent.getAttribute('ng-reflect-fx-layout');
                        flexDirection = this.getFlowDirection(fxLayout);
                    }
                    let siblingAfter = this.el.nativeElement.nextElementSibling;
                    if (flexDirection === 'row') {

                        if (siblingAfter) {
                            this.renderer.setStyle(this.el.nativeElement, 'margin-right', flexGap);
                            this.renderer.removeStyle(this.el.nativeElement, 'margin-bottom');
                        }
                        this.renderer.setStyle(this.el.nativeElement, 'max-width', this.fxFlex + '%');
                        this.renderer.removeStyle(this.el.nativeElement, 'max-height');
                    } else {
                        if (siblingAfter) {
                            this.renderer.setStyle(this.el.nativeElement, 'margin-bottom', flexGap);
                            this.renderer.removeStyle(this.el.nativeElement, 'margin-right');
                        }
                        this.renderer.setStyle(this.el.nativeElement, 'max-height', this.fxFlex + '%');
                        this.renderer.removeStyle(this.el.nativeElement, 'max-width');
                    }
                }
            }
        }
    }
    private ApplyfxLayoutFlex() {
        if (this.el.nativeElement.hasAttribute('ng-reflect-fx-flex')) {
            let flexFlex = this.el.nativeElement.getAttribute('ng-reflect-fx-flex');
            if (flexFlex) {
                let fxLayout = undefined;// ng-reflect-fx-layout="column"
                // let flexFlow = undefined;// ng-reflect-fx-layout="column"
                let flexDirection = 'column';// ng-reflect-fx-layout="column"
                const parent = this.el.nativeElement.parentElement;
                if (parent && parent.hasAttribute('ng-reflect-fx-layout')) {
                    fxLayout = parent.getAttribute('ng-reflect-fx-layout');
                    flexDirection = this.getFlowDirection(fxLayout);
                    // flexFlow = this.getFlow(fxLayout);
                }
                if (flexDirection === 'row') {
                    this.renderer.setStyle(this.el.nativeElement, 'max-width', this.fxFlex + '%');
                    this.renderer.removeStyle(this.el.nativeElement, 'max-height');
                } else {
                    this.renderer.setStyle(this.el.nativeElement, 'max-height', this.fxFlex + '%');
                    this.renderer.removeStyle(this.el.nativeElement, 'max-width');
                }
                // if (flexFlow) {
                //     this.renderer.setStyle(this.el.nativeElement, 'flex-flow', flexFlow);
                // }
            }
        }
    }
    private updateLayoutDirection() {
        const screenWidth = document && document.body && document.body.clientWidth ? document.body.clientWidth : window.innerWidth;

        // Set layout direction based on screen size
        let fxLayout = undefined
        if (screenWidth < 576) {
            fxLayout = this.fxLayoutSm || this.fxLayoutLtSm || this.fxLayoutGtSm || this.fxLayoutMd || this.fxLayoutLtMd || this.fxLayoutGtMd || this.fxLayout;
        } else if (screenWidth < 960) {
            fxLayout = this.fxLayoutMd || this.fxLayoutLtMd || this.fxLayoutGtMd || this.fxLayout;
        } else if (screenWidth >= 1200) {
            fxLayout = this.fxLayoutLg || this.fxLayoutLtLg || this.fxLayoutGtLg || this.fxLayout;
        } else {
            fxLayout = this.fxLayout;
        }
        if (fxLayout) {
            let flexDirection = this.getFlowDirection(fxLayout);
            let flexFlow = this.getFlow(fxLayout);
            this.renderer.setStyle(this.el.nativeElement, 'flex-direction', flexDirection);
            this.renderer.setStyle(this.el.nativeElement, 'flex-flow', flexFlow);
            this.renderer.setAttribute(this.el.nativeElement, 'ng-reflect-fx-layout', fxLayout);
        }
    }
}

