<div class="kt-portlet">
	<div class="kt-portlet__head kt-portlet__head__custom">
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">{{data.title}}</h3>
			<span class="kt-portlet__head-icon kt-hide">
				<i class="la la-gear"></i>
			</span>
		</div>
	</div>
	<div class="kt-form">
		<div class="kt-portlet__body">
			<div class="form-group kt-form__group row">
				<div class="col-lg-12">
					{{data.description}}
				</div>
			</div>
			<div class="form-group kt-form__group row">
				<div class="col-lg-12">
					<mat-form-field class="example-full-width" style="margin-top: 2vh;">
						<input matInput *ngIf="data.type && data.type === 'number'" type="number" [min]="data.min"
							[max]="data.max" [(ngModel)]="data.data" placeholder="{{data.placeholder}}">
						<input matInput *ngIf="!(data.type && data.type === 'number') && data.type !== 'textarea'"
							[(ngModel)]="data.data" placeholder="{{data.placeholder}}">
						<textarea [required]="data.inputRequired" [placeholder]="data.placeholder" matInput
							[maxlength]="data.maxLength" *ngIf="data.type == 'textarea'"
							[(ngModel)]="data.data"></textarea>
						<mat-error *ngIf="data.inputRequired && !data.data">
							{{'Required field' | translate}}
						</mat-error>
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
			<div class="kt-form__actions kt-form__actions--sm">
				<br />
				<button mat-raised-button (click)="onNoClick()" color="danger">{{data.cancelbtn}}</button>&nbsp;
				<button mat-raised-button (click)="onYesClick()" [disabled]="data.inputRequired && !data.data"
					cdkFocusInitial>{{data.confirmbtn}}</button>
			</div>
		</div>
	</div>
</div>