<div class="col-xl-12 galleryPopup">
	<h1 mat-dialog-title>{{data.title | translate}}</h1>
	<div mat-dialog-content class="dialog-content">
		<mat-tab-group [selectedIndex]="tabSelected" (selectedIndexChange)="tabSelected = $event;" dynamicHeight>
			<mat-tab label="{{'Images' | translate}}">
			</mat-tab>
			<mat-tab #tabCrop label="{{'Crop' | translate}}" [disabled]="!imageSelected">
			</mat-tab>
		</mat-tab-group>
		<div *ngIf="tabSelected===0">
			<div class="example-small-box" *ngFor="let itm of dataList; let index=index"
				[class.selected]="itm.imageUrl === imageSelected?.imageUrl" (click)="setImageSelected(index, itm)">
				<div class="itemImage">
					<div class="topIcons">
						<i class="material-icons" (click)="delete($event, itm._id)">delete_forever</i>
					</div>
					<img class="imageAppear" src="{{itm.imageUrl}}">
					<div class="itemData">
						<div class="dimentions">
							{{itm.dimentions}}
						</div>
						<div class="size">
							{{itm.size}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="tabSelected===1">
			<image-cropper *ngIf="imageUrlPath" style="height:300px" [imageBase64]="imageUrlPath"
				[maintainAspectRatio]="!(selectedRatio === 0)" [aspectRatio]="selectedRatio" format="png"
				[autoCrop]="false" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
				[onlyScaleDown]="true" (cropperReady)="cropperReady()" [roundCropper]="true"
				(loadImageFailed)="loadImageFailed()"></image-cropper>
		</div>
	</div>
	<div mat-dialog-actions class="dialog-actions">
		<mat-paginator [hidden]="tabSelected === 1" class="paginator" [pageSizeOptions]="[10,20,30]"
			[pageSize]="pageSize" [length]="paginatorTotal | async" [showFirstLastButtons]="true"
			[pageIndex]="pageNumber"></mat-paginator>
		<mat-divider></mat-divider>
		<div style="width: 100%;margin-top:10px;">
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">Close</button>
			<input #imageInput [hidden]="true" type="file" name="upfileLogo" accept="image/*" multiple="false"
				id="image_selector" (change)="onBrowseFiles($event.target)">
			<button mat-raised-button (click)="imageInput.click()">Upload</button>
			<button mat-raised-button (click)="tabSelected = 1;prepareImageCrop()"
				[hidden]="!imageSelected || tabSelected === 1">Crop</button>
			<button mat-raised-button (click)="cropIt()" [hidden]="!imageSelected || tabSelected !== 1">Confirm
				Crop</button>
			<!-- <button mat-raised-button (click)="closeModal({action: 'select' , image: imageSelected})"
				[hidden]="!imageSelected">Select without
				Crop</button> -->
			<button mat-raised-button (click)="closeModal({action: 'select' , image: imageSelected})"
				[hidden]="!imageSelected || tabSelected === 1">Select</button>
			<button mat-raised-button (click)="clearIt($event)" *ngIf="data.selectedImage"
				[disabled]="loading">{{'Clear' | translate}}</button>
		</div>
	</div>
	<br />
</div>