import { Component, Inject, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { urlSafeBase64Encoding } from '../../../shared/helpers';

interface ViewDialogData {
	title: string;
	data: any;
	confirmData: any;
}

@Component({
	selector: 'app-iframe-dialog-modal',
	templateUrl: './iframe-dialog.component.html',
	styleUrls: ['./iframe-dialog.component.scss']
})
export class ModalIframeDialogComponent implements OnInit {
	public errorMessage: string = '';
	public loading: boolean = false;
	public iframe_html: SafeResourceUrl;
	public iframeHeight: string = '90%';
	@ViewChild('iframeMain') iframeMain: ElementRef;
	constructor(
		private requestService: RequestService, private sanitizer: DomSanitizer,
		private layoutUtilsService: LayoutUtilsService, private loaderService: LoaderService,
		public dialogRef: MatDialogRef<ModalIframeDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {

		this.iframe_html = undefined;
	}

	ngOnInit() {
		this.loadData();

	}
	public loadData() {
		this.iframe_html = this.transform(this.data.data['url']);
		this.loading = true;
		
	}

	onload(ev: Event) {
		this.resizeIframe();
		this.loading = false;
	}
	public pageY(elem) {
		return elem.offsetParent ? (elem.offsetTop + this.pageY(elem.offsetParent)) : elem.offsetTop;
	}
	public resizeIframe() {

		let height = document.documentElement.clientHeight - 75;
		if (this.iframeMain) {
			height -= this.pageY(this.iframeMain.nativeElement) + 20;
		}
		height = (height < 0) ? 0 : height;
		this.iframeHeight = height + 'px';
	}
	transform(url) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		console.log(event.target.innerWidth);
		this.resizeIframe()
	}
}
