import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { ResizeObserver } from '@juggle/resize-observer';

@Directive({
    selector: '[appRemoveOnWidth]'
})
export class HideOnWidthDirective implements OnInit, OnDestroy {
    private hasView = false;
    private resizeObserver: ResizeObserver;

    @Input('appRemoveOnWidth') widthThreshold: number;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) { }

    ngOnInit(): void {
        this.resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                this.updateView(entry.contentRect.width);
            }
        });

        this.resizeObserver.observe(document.body);
        this.updateView(window.innerWidth); // Initial check based on current window width
    }

    ngOnDestroy(): void {
        this.resizeObserver.disconnect();
    }

    private updateView(width: number): void {
        if (width <= this.widthThreshold) {
            if (!this.hasView) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.hasView = true;
            }
        } else {
            if (this.hasView) {
                this.viewContainer.clear();
                this.hasView = false;
            }
        }
    }
}