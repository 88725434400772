import { ErrorHandler, Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as Rollbar from 'rollbar';
import { VERSION } from 'src/version';
import { RequestService } from './request.service';

@Injectable()
export class LoggerService {
  public errorObject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  log(error) {
    // console.log('My Error:', error);
    this.errorObject.next(error);
  }
}

let rollbarConfig = {
  accessToken: environment.rollbar.apiKey,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: environment.environment === 'local' ? false : true,
  payload: {
    environment: environment.environment,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: VERSION.git,
        guess_uncaught_frames: true,
      }
    }
  }
};

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

  public showError: boolean = true;
  currentUser: any = undefined;

  constructor(private requestService: RequestService, private injector: Injector, private logger: LoggerService, @Inject(RollbarService) private rollbar: Rollbar) {
    super();
    this.currentUser = this.requestService.currentUserSubject.getValue();
  }

  handleError(error) {
    let router = this.injector.get(Router);

    if (this.currentUser)
      this.rollbar.configure({
        payload: {
          person: {
            id: this.currentUser._id,
            email: this.currentUser.email,
            name: this.currentUser.name
          }
        }
      });

    this.rollbar.error(error);

    // router.navigate(['/error/400']);
    if (error instanceof HttpErrorResponse) {
      //Backend returns unsuccessful response codes such as 404, 500 etc.
      console.error('Backend returned status code: ', error.status);
      console.error('Response body:', error.message);
      router.navigate(['/error/400']);
    } else {
      //A client-side or network error occurred.
      this.logger.log(error.message);
    }
    if (this.showError) {
      this.showError = false;
      super.handleError(error);
    }
  }
}
