import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { RequestService, MenuConfigService } from '../../../shared/services';
import { environment } from '../../../../environments/environment';
// Object path
import * as objectPath from 'object-path';
import { ActivatedRoute, NavigationEnd, NavigationError, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseHandlersService } from '../../services/firebase-handlers.service';
import { ModalUserViewDialogComponent } from '../custom-user-view-dialog/custom-user-view-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalLanguageDialogComponent } from '../custom-language-dialog/custom-language-dialog.component';
import { NotificationService } from '../../services/notification.service';
import * as moment from 'moment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public isAdmin: boolean = false;
  public isSuperAdmin: boolean = false;
  public showMenu: string;
  public languageSelected: string = 'en';
  public menuConfigs: any[] = [];
  public pushRightClass: string = 'push-right';
  public showNotificationUnRead: boolean = false;
  public currentRoute: string = '';

  constructor(private requestService: RequestService, public router: Router, private menuConfigService: MenuConfigService, private translate: TranslateService, private firebaseHandler: FirebaseHandlersService, private activatedRoute: ActivatedRoute, private notificationService: NotificationService,
    public dialog: MatDialog) {
    if (localStorage.getItem('languageSelected')) {
      this.languageSelected = localStorage.getItem('languageSelected');
    }
    this.menuConfigs = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');

    router.events.subscribe((data) => {
      if (data instanceof NavigationEnd ||
        data instanceof NavigationError) {
        this.currentRoute = activatedRoute.snapshot['_routerState'].url;
      }
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.menuConfigService.onConfigUpdated$.subscribe((data) => {
        if (data) {
          this.menuConfigs = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');

        }
      })
    );
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.isAdmin = this.requestService.isUserRoleAdmin();
          this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();
        }
      })
    );

    this.subscriptions.push(this.notificationService.showNotificationUnRead.subscribe((show: any) => {
      if (this.currentRoute !== '/notifications' || !show)
        this.showNotificationUnRead = show;
    }));

    this.showMenu = 'admin';
    this.getNotifications();
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  private getNotifications() {
    this.notificationService.getNotifications('', 0, 1).then((data: any) => {
      if (localStorage.getItem('last-seen-notifications')) {
        let lastSeen = moment(Number(localStorage.getItem('last-seen-notifications'))).utc();
        if (lastSeen && lastSeen.isBefore(moment(data.results[0]?.createdAt))) {
          this.showNotificationUnRead = true;
        }
      }
    }).catch((error: any) => {

    }).finally(() => {

    });
  }

  clickingOutside() {
    const dom: any = document.querySelector('body');
    dom.classList.remove(this.pushRightClass);
  }
  hideMenu() {
    this.toggleSidebar();
  }
  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  public goHome() {
    this.router.navigate(['/cases/list']);
  }
  public logout() {
    this.clickingOutside();
    this.firebaseHandler.logout();
  }
  viewProfile() {
    const dialogRef = this.dialog.open(ModalUserViewDialogComponent, {
      width: '850px',
      data: {
        dataType: 'user',
        title: this.translate.instant('My Profile'),
        profile: true,
        data: this.selectedUser,
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('result', result);
      }
    });
  }
  changeLang() {
    if (this.languageSelected === 'en') {
      this.languageSelected = 'ja';
    } else {
      this.languageSelected = 'en';
    }
    localStorage.setItem('languageSelected', this.languageSelected);
    this.translate.use(this.languageSelected);
    location.reload();
  }

  changeLanguage() {
    const dialogRef = this.dialog.open(ModalLanguageDialogComponent, {
      width: '500px',
      data: {
        title: this.translate.instant('Language'),
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('result', result);
      }
    });
  }
}
