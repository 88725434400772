import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';

interface ViewDialogData {
	title: string;
	data: any;
	confirmData: any;
}

@Component({
	selector: 'app-language-dialog-modal',
	templateUrl: './custom-language-dialog.component.html',
	styleUrls: ['./custom-language-dialog.component.scss']
})
export class ModalLanguageDialogComponent implements OnInit {
	public languageSelected: string = 'en';
	constructor(private translate: TranslateService,
		public dialogRef: MatDialogRef<ModalLanguageDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		//console.log('ViewDialogData', data);
		if (localStorage.getItem('languageSelected')) {
			this.languageSelected = localStorage.getItem('languageSelected');
		}
	}

	ngOnInit() {
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
	changeLang(language: string) {
		localStorage.setItem('languageSelected', language);
		this.languageSelected = language;
		this.translate.use(language);
		window.location.reload();
	}
}
