<div *ngIf="casesList && casesList.length == 0" class="noResults">
    {{'No records found' | translate}}
</div>
<div *ngIf="metaFieldSetting && groupedData && casesList && casesList.length > 0">
    <div *ngFor="let date of objectKeys(groupedData)">
        <div class="dateTitle">{{ date }}</div>
        <div class="caseItem" [class.noBorder]="index === groupedData[date].length - 1" fxLayout="row"
            fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngFor="let item of groupedData[date];let index = index">
            <div fxFlex="10" class="box" fxLayout="column" fxLayoutGap="2vh" class="dateColumn">
                <div class="dateCss">{{ item.createdAt | date: 'yyyy/MM/dd'}}</div>
                <div class="timeCss">{{ item.createdAt | date: 'HH:mm' }}</div>
            </div>
            <div fxFlex="15" class="box" fxLayout="column" fxLayoutGap="2vh">
                {{item['department']}}
                <div><span>{{'Case Type' | translate}}</span>: <app-enum-view [enumValue]="item['case_type']"
                        [enumList]="metaFieldSetting['case_type'].enum">
                    </app-enum-view></div>
                <div><span>{{'Case number' | translate}}</span>: {{item['case_id']}}</div>
            </div>
            <div fxFlex="35" class="box" fxLayout="column" fxLayoutGap="2vh">
                <div class="caseTitle" (click)="openCase(item._id)"><span *ngIf="item.name">{{ item.name }}</span><span
                        *ngIf="!item.name">{{ item.case_id }}</span></div>
                <div class="caseDescription">{{ item.description }}</div>
            </div>
            <div fxFlex="30" class="box" fxLayout="column" fxLayoutGap="2vh" *ngIf="locale === 'en'">
                <div class="caseAddress" (click)="openCaseMap(item._id)">
                    <span>{{ item.address }}</span>
                </div>
            </div>
            <div fxFlex="30" class="box" fxLayout="column" fxLayoutGap="2vh" *ngIf="locale === 'ja'">
                <div class="caseAddress" (click)="openCaseMap(item._id)">
                    <span>{{ item.address_jp }}</span>
                </div>
            </div>
            <div fxFlex="10" class="box" fxLayout="column" fxLayoutGap="2vh" fxLayoutAlign="center center"
                *ngIf="!isInvestigator">
                <button mat-raised-button style="width: fit-content;" color="danger"
                    (click)="edit(item, index)">{{'Edit' |
                    translate}}</button>
            </div>
        </div>
    </div>
</div>