import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';

class MyDialogErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

interface DialogData {
  dataType: string;
  dataTypeTitle: string;
  templateSettings: any;
  templateDisabled: boolean;
  title: string;
  data: any;
  modalSetting: any;
  confirmData: any;
}

@Component({
  selector: 'app-layout-dialog-modal',
  templateUrl: './custom-layout-dialog.component.html',
  styleUrls: ['./custom-layout-dialog.component.scss']
})
export class ModalLayoutDialogComponent implements OnInit {
  public errorMessage: string = '';
  public loading: boolean = false;
  public showMobile: boolean = false;
  public originalTableSetting: any = undefined;
  public templateSettings: any = undefined;
  public templateDisabled: boolean = false;
  constructor(private translate: TranslateService, public dialog: MatDialog,
    private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService, private changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ModalLayoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    //console.log('DialogData', data);
  }
  ngOnInit() {
    if (this.data.templateSettings) {
      this.templateSettings = this.data.templateSettings;
    }
    if (this.data.templateDisabled) {
      this.templateDisabled = this.data.templateDisabled;
    }
  }
  closeModal(data): void {
    this.dialogRef.close(data);
  }
}
