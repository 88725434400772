import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { StoreService } from '../../shared/services';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private storeService: StoreService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('currentUser')) {
      // logged in so return true
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (this.legitUser(currentUser)) {
        return true;
      }
    }
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
  private legitUser(user): boolean {
    if (user.hasOwnProperty('_id') && user.hasOwnProperty('token') && user.hasOwnProperty('resources')) {
      let rolesCanAccess = environment.rolesCanAccess;
      if (user['resources'].find(role => rolesCanAccess.includes(role['_id']))) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
