<div class="col-xl-12">

	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<div mat-dialog-content *ngIf="data['dataType'] == 'tile'" style="padding-bottom: 10px;">
			<div> Name: {{data['firstName']}} {{data['lastName']}}</div>
			<div *ngIf="data['tilesAssignedDate']"> Assigned Date: {{data['tilesAssignedDate'] | date: "MM-dd-yyyy hh:mm
				a"}}</div>
			<div *ngIf="data['updateDate']"> Submission Date: {{data['updateDate'] | date: "MM-dd-yyyy hh:mm a" }}</div>
		</div>
		<div class="fieldItem" [style.height]="iframeHeight">
			<span *ngIf="loading">Loading...</span>

			<iframe id="iframeMain" #iframeMain width="100%" frameBorder="0" [src]="iframe_html" (load)="onload($event)"
				[height]="iframeHeight"></iframe>
		</div>

	</div>
	<div mat-dialog-actions style="justify-content:flex-end">
		<button mat-raised-button color='white' (click)="closeModal(undefined)">Close</button>
	</div>

	<br />
</div>