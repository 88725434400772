import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  // MAT_LABEL_GLOBAL_OPTIONS,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgxPaginationModule } from 'ngx-pagination';
// import { LeafletModule } from '@asymmetrik/ngx-leaflet';
// import { AgmCoreModule } from '@agm/core';
import { NgxPermissionsModule } from './modules/ngx-permissions';
import { MatBottomSheet, MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgSelectModule } from '@ng-select/ng-select';
//import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';


import { MatIconRegistry } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';

// import {MatExpansionModule} from '@angular/material/expansion';
import { FormsModule as FormModule } from '@angular/forms';


// import { FromNowPipe } from './pipes/from-now.pipe';
// import { CapitalizePipe } from './pipes/capitalize.pipe';
//import { SafePipe } from './pipes/safe.pipe';

/* components */
// import { CardComponent } from './components/card/card.component';
// import { TodolistComponent } from './components/todolist/todolist.component';
// import { TabsetComponent } from './components/tabset/tabset.component';
// import { TabContentComponent } from './components/tabset/tab-content/tab-content.component';
// import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
// import { FileTreeComponent } from './components/file-tree/file-tree.component';
// import { SwitchComponent } from './components/switch/switch.component';
// import { PellEditorComponent } from './components/pell-editor/pell-editor.component';
// import { AlertComponent } from './components/alert/alert.component';
// import { WeatherComponent } from './components/weather/weather.component';
// import { ProfileComponent } from './components/profile/profile.component';
// import { CustomFormComponent } from './components/custom-form/custom-form.component';
// import { CustomTableComponent } from './components/custom-table/custom-table.component';
// import { CustomSelectComponent } from './components/custom-select/custom-select.component';
// import { CustomModalComponent } from './components/custom-modal/custom-modal.component';
// import { RolesModalComponent } from './components/roles-modal/roles-modal.component';
// import { LocationsModalComponent } from './components/locations-modal/locations-modal.component';
// import { ServiceTypesModalComponent } from './components/service-types-modal/service-types-modal.component';
// import { CustomMapComponent } from './components/custom-map/custom-map.component';
// import { CustomListComponent } from './components/custom-list/custom-list.component';
// import { ChatUIComponent } from './components/chat-ui/chat-ui.component';
// import { ModalModule } from 'ngx-modal';
//
import {
  FromNowPipe,
  CapitalizePipe,
  FirstLetterPipe,
  PermissionUIPipe,
  SafePipe,
  TruncatePipe,
  CountDown,
  CountDownInSec,
  EnumPipe,
} from './pipes';
// import { FromNowPipe } from './pipes/from-now.pipe';
// import { CapitalizePipe } from './pipes/capitalize.pipe';
// import { SafePipe } from './pipes/safe.pipe';
// import { SafePipe } from './pipes/truncate.pipe';
//
// import {DndModule} from 'ng2-dnd'.;

@NgModule({
  imports: [
    CommonModule,
    // NgxPaginationModule,
    // LeafletModule,
    // AgmCoreModule,
    FormsModule,
    ReactiveFormsModule,
    // ModalModule,
    // DndModule.forRoot(),
    MatTooltipModule,
  ],
  declarations: [
    // CardComponent,
    // FileTreeComponent,
    // TodolistComponent,
    // TabsetComponent,
    // TabContentComponent,
    // ProgressBarComponent,
    // SwitchComponent,
    // PellEditorComponent,
    // AlertComponent,
    // WeatherComponent,
    // ProfileComponent,
    // CustomFormComponent,
    // CustomTableComponent,
    // CustomSelectComponent,
    // CustomModalComponent,
    // RolesModalComponent,
    // LocationsModalComponent,
    // ServiceTypesModalComponent,
    // CustomListComponent,
    // ChatUIComponent,
    // CustomMapComponent,
    FromNowPipe,
    CapitalizePipe,
    SafePipe,
    EnumPipe,
    FirstLetterPipe,
    PermissionUIPipe,
    TruncatePipe,
    CountDown,
    CountDownInSec,
  ],
  exports: [
    // CardComponent,
    // FileTreeComponent,
    // TodolistComponent,
    // TabsetComponent,
    // TabContentComponent,
    // ProgressBarComponent,
    // SwitchComponent,
    // PellEditorComponent,
    // AlertComponent,
    // WeatherComponent,
    // ProfileComponent,
    // FormsModule,
    // ReactiveFormsModule,
    // CustomFormComponent,
    // CustomTableComponent,
    // CustomSelectComponent,
    // CustomModalComponent,
    // RolesModalComponent,
    // LocationsModalComponent,
    // ServiceTypesModalComponent,
    // CustomListComponent,
    // ChatUIComponent,
    // CustomMapComponent,
    FromNowPipe,
    CapitalizePipe,
    SafePipe,
    EnumPipe,
    FirstLetterPipe,
    PermissionUIPipe,
    TruncatePipe,
    CountDown,
    CountDownInSec,
    // KeysPipe
    FormModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTooltipModule,
    MatCardModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatTableModule,
    MatTabsModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatProgressSpinnerModule,
    MatSortModule,
    NgxPermissionsModule,
    NgSelectModule,
    MatExpansionModule,
    MatTreeModule,
    DragDropModule,
    MatTooltipModule,
  ],
  providers: [
    // { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
})
export class SharedModule { }
