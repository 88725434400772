import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-custom-select-create-autocomplete',
  templateUrl: './custom-select-create-autocomplete.component.html',
  styleUrls: ['./custom-select-create-autocomplete.component.scss']
})
export class CustomSelectCreateAutocompleteComponent implements OnInit {
  public apiCallSubscription: Subscription = undefined;
  public errorMessage: string = '';
  public loading: boolean = false;
  public passingThru: boolean = false;
  public dataText: string = 'Select All';
  selectData: Array<any> = [];
  filteredData: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  /* pagination Info */
  @Input() pageSize: number = 30;
  pageNumber = 1;
  orderDir = 'asc';
  orderBy = 'name';
  searchText = '';

  @Input() simple: boolean = false;
  @Input() clearable: boolean = false;
  @Input() canDelete: boolean = false;
  @Input() onBlur: boolean = false;
  @Input() hasAddBtn: boolean = false;
  @Input() canNewList: string[] = undefined;
  @Input() hasEditBtn: boolean = false;
  @Input() addAll: boolean = false;
  @Input() useOrg: boolean = false;
  @Input() filters: any;
  @Input() displayName: string[] = ['name', 'text'];
  @Input() termfields: string[] = ['name'];
  @Input() placeholder: string = '';
  @Input() type: string = undefined;
  @Input() dataType: string;
  @Input() dataTypeDisplay: string;
  public _value: any = undefined;
  @Input()
  set value(value: any) {
    // console.log(this.dataType, ' value ', value);
    if (value) {
      if ((value !== '-1' && value !== '')) {
        if (value !== this._value) {
          this._value = value;
          this.loadDataSearch(true);
          // this.dataText = JSON.parse(JSON.stringify(value));
        }
      } else {
        this._value = value;
        if (this.addAll) {
          this.dataText = 'Select All';
        } else {
          this.dataText = '';
        }
      }
    } else {
      this._value = value;
      if (this.addAll) {
        this.dataText = 'Select All';
      } else {
        this.dataText = '';
      }
    }
  }
  get value(): any {
    return this._value;
  }
  @Output() onSelectReturn = new EventEmitter<any>();
  @Output() onReturnSignal = new EventEmitter<any>();
  @ViewChild('inputName') inputName: ElementRef;
  constructor(
    private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService, private translate: TranslateService
  ) { }

  ngOnInit() {
    this.loadDataSearch(true);
  }
  triggerButtun(e, name) {
    if (e) {
      e.stopImmediatePropagation();
      e.stopPropagation();
      e.preventDefault();
    }
    document.getElementById(name).click();
  }
  private getSelectedName(val) {
    for (let itm of this.selectData) {
      if (val === itm.text) {
        return itm
      }
    }
    return undefined;
  }
  private getSelectedItem(val) {
    for (let itm of this.selectData) {
      if (val === itm._id) {
        return itm
      }
    }
    return undefined;
  }
  displayFn(data?: any): string | undefined {
    return data ? data.text : undefined;
  }
  public clearData() {
    if (this.addAll) {
      this.dataText = 'Select All';
    } else {
      this.dataText = '';
    }
  }
  public reset(val) {
    this._value = val._id;
  }
  public loadData() {
    this.loadDataSearch();
  }
  public loadDataSearch(setName: boolean = false) {
    if (this.loading) {
      // this.apiCallSubscription.unsubscribe();
      this.loading = false;
    }
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let filterConfiguration = undefined;
      if (this.filters) {
        filterConfiguration = JSON.parse(JSON.stringify(this.filters));
      }

      if (this.useOrg || this.type) {
        if (!filterConfiguration) {
          filterConfiguration = { '$and': [] };
        }
        if (this.useOrg)
          filterConfiguration['$and'].push({ 'organizationId': { '$eq': this.requestService.orgId } });
        if (this.type)
          filterConfiguration['$and'].push({ 'type': { '$eq': this.type } });
      }

      let include = undefined;
      if (this.value) {
        include = [];
        include.push(this.value);
      }
      let filterObj = {
        page: this.pageNumber,
        orderDir: this.orderDir,
        orderBy: this.orderBy,
        term: this.searchText,
        perpage: this.pageSize,
        filter: filterConfiguration,
        termfields: this.termfields,
        include: include
      };
      // this.apiCallSubscription = 
      this.requestService.getDataListSummary(this.dataType, filterObj, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, 'Dismiss');
        }
        if (data) {
          this.selectData = data.results;
          if (setName) {
            let selectedObj = this.getSelectedItem(this.value);
            if (selectedObj) {
              this.dataText = selectedObj;
            } else {
              if (this.addAll) {
                this.dataText = 'Select All';
              } else {
                this.dataText = '';
              }
            }
          }
        } else {
          if (this.addAll) {
            this.dataText = 'Select All';
          } else {
            this.dataText = '';
          }
          this.selectData = [];
        }
        this.filteredData.next(this.selectData);
        this.loading = false;
      });
    }
  }
  sendSignal(e, action): any {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.onReturnSignal.emit(action);
  }
  termSearch(term): any {
    this.searchText = term;
    this.onSelectReturn.emit({ _id: undefined, text: term });
    this.loadDataSearch();
  }
  public setAttribute(e, val) {
    this.passingThru = true;
    this._value = val._id;
    this.dataText = val;
    this.onSelectReturn.emit(val);
  }
  public setSimpleAttribute(e, val) {
    if (val) {
      let objectVal = { _id: val, text: this.getSelectedName(val) };
      this.passingThru = true;
      this._value = objectVal._id;
      this.dataText = objectVal.text;
      this.onSelectReturn.emit(objectVal);
    } else {
      let objectVal = { _id: val, text: this.getSelectedName(val) };
      this.passingThru = true;
      this._value = '-1';
      this.dataText = 'Select All';
      this.onSelectReturn.emit(objectVal);
    }
  }
  public onBlurMethod(event) {
    setTimeout(() => {
      if (!this.passingThru) {
        this.createIt(event);
      } else {
        this.passingThru = false;
      }
    }, 500);
  }

  public createIt(event) {
    if (this.onBlur && event.trim().length > 0) {
      let selectedItem = this.getSelectedName(event);
      if (!selectedItem) {
        if (!this.loading) {
          const _title: string = 'Create ' + this.dataTypeDisplay;
          const _description: string = 'Are you sure you want to create this ' + this.dataTypeDisplay + '?';
          let alertSetting = {
            overlayClickToClose: false,
            showCloseButton: false,
            confirmText: this.translate.instant('Confirm'),
            declineText: this.translate.instant('Cancel'),
          };
          const dialogRef = this.layoutUtilsService.alertActionElement(_title, _description, alertSetting);
          dialogRef.afterClosed().subscribe(res => {
            if (res && res.action === 'confirmText') {
              this.saveObject(event);
            }
          });
        }
      } else {
        this.onSelectReturn.emit(selectedItem);
      }
    }
  }
  public deleteItem(e, val) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (!this.loading) {
      const _title: string = this.translate.instant('Deletion') + ' ' + this.dataTypeDisplay;
      const _description: string = 'Are you sure you want to permanently delete this ' + this.dataTypeDisplay + '?';
      const _waitDesciption: string = this.translate.instant('Deleting') + '...';

      const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return;
        }
        this.realDelete(val._id);
      });
    }
  }
  public realDelete(id: any) {
    const _deleteMessage = this.dataTypeDisplay + ' ' + this.translate.instant('deleted successfully');
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.deleteSingleData(this.dataType, id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, 'Dismiss');
        }
        this.loading = false;
        if (data) {
          this.layoutUtilsService.showNotification(_deleteMessage, 'Dismiss');
          this.loadData();
        }
      });
    }
  }
  saveObject(dataText) {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let dataObject = {
        name: dataText,
        // "color": "rgb(88,225,91)",
      }
      if (this.useOrg) {
        dataObject['organizationId'] = this.requestService.orgId;
      }
      if (this.type) {
        dataObject['type'] = this.type;
      }
      this.requestService.saveData(this.dataType, dataObject, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, 'Dismiss');
        }
        if (data) {
          let returnedData = data.results;
          if (this.simple) {
            this.setSimpleAttribute(undefined, returnedData._id);
          } else {
            let selectedDataName = { _id: returnedData._id, text: data.results.name };
            this.setAttribute(undefined, selectedDataName);
          }
          this.layoutUtilsService.showNotification(this.translate.instant(this.dataTypeDisplay) + ' ' + this.translate.instant('created successfully'), 'Dismiss');
        }
        this.loadDataSearch(true);
        this.loading = false;
      });
    }
  }
}
