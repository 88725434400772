import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';

interface ViewDialogData {
	title: string;
	data: any;
	confirmData: any;
}

@Component({
	selector: 'app-collection-dialog-modal',
	templateUrl: './custom-collection-dialog.component.html',
	styleUrls: ['./custom-collection-dialog.component.scss']
})
export class ModalCollectionDialogComponent implements OnInit {
	public collectionName: string = '';
	constructor(private translate: TranslateService,
		protected layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ModalCollectionDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		//console.log('ViewDialogData', data);
	}

	ngOnInit() {
	}
	closeModal(data): void {
		if (data) {
			if (this.collectionName === '') {
				this.layoutUtilsService.showNotification(this.translate.instant('Name is required'), this.translate.instant('Dismiss'));
				return;
			} else {
				if (this.collectionName.length > 150) {
					this.layoutUtilsService.showNotification(this.translate.instant('Name') + ' ' + this.translate.instant('should have a maximum of') + ' ' + 150 + ' ' + this.translate.instant('characters'), this.translate.instant('Dismiss'));
					return;
				}
			}
		}
		this.dialogRef.close(data);
	}
}
