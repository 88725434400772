<div class="col-xl-12" *ngIf="data">
	<div mat-dialog-content *ngIf="data.confirmData && !(data.data.isAnonymous)">
		<div *ngIf="fieldsDetails">
			<div class="mb-20 profile-page-header-main" fxLayout="row" fxLayout.lt-md="column">
				<div fxFlex class="row1">
					<figure class="card-profile-image">
						<img style="max-height: 150px;" [src]="pictureLink"
							class="circle z-depth-2 responsive-img activator">
						<mat-icon *ngIf="isProfile" (click)="editProfileImage()" class="edit"
							title="{{ 'Edit' | translate }}">edit
						</mat-icon>
					</figure>
					<div class="name">{{data.confirmData['title']}}
						{{data.confirmData['lastName']}} {{data.confirmData['firstName']}}
					</div>
					<div class="email">
						<span class="inlineText">{{data.confirmData['badge_no']}}</span>
					</div>
					<div class="email" *ngIf="fieldsDetails">
						<span class="inlineText">
							<ng-container *ngFor="let res of data.confirmData['resources'];let index = index">
								<div class="inlineText" *ngIf="organization._id === res.organizationId ">
									{{data.confirmData['resources'][index]['name'] | translate}}</div>
							</ng-container>
						</span>
					</div>
					<div class="email" *ngIf="data.confirmData['phone'] && data.confirmData['phone'] !== ''">
						<span class="inlineText">{{fieldsDetails['phone']['displayName']
							| translate}} : {{data.confirmData['phone']}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="data.data.isAnonymous" mat-dialog-content class="anonymous-dialog">
		<div class="anonymous-dialog-msg">
			This account belongs to an anonymous user. There are no profile details associated with it.
		</div>
	</div>
	<div mat-dialog-actions>
		<div class="btnList" fxLayout="row" fxLayout.lt-md="column" fxFlex
			*ngIf="data.modalSetting && !data.modalSetting.hasOwnProperty('footer')">
			<div fxFlex style="align-items: center; display: flex;">
				<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' |
					translate}}</button>
				<button *ngIf="isProfile" mat-raised-button (click)="editProfile()">
					{{'Edit Profile' | translate}}
				</button>
				<button *ngIf="isProfile && showChangePassword" mat-raised-button (click)="changePassword()">
					{{'Change Password' | translate}}
				</button>
				<div fxFlex fxLayoutAlign="end center" fxGrow="1" class="dnd">
					<mat-slide-toggle [(ngModel)]="isDnd" color="primary" [disableRipple]="true"
						(change)="saveDnd()">{{'DND' |
						translate}}</mat-slide-toggle>
				</div>
				<!-- <button *ngIf="isProfile" mat-raised-button (click)="changePassword()">
					{{'Change Password' | translate}}
				</button> -->
			</div>
		</div>
	</div>
</div>