import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { urlSafeBase64Encoding } from '../../../shared/helpers';

interface ViewDialogData {
	dataType: string;
	subDataType: string;
	title: string;
	data: any;
	modalSetting: any;
	confirmData: any;
}

@Component({
	selector: 'app-carplate-view-dialog-modal',
	templateUrl: './custom-carplate-view-dialog.component.html',
	styleUrls: ['./custom-carplate-view-dialog.component.scss']
})
export class ModalCarplateViewDialogComponent implements OnInit {
	public errorMessage: string = '';
	public isVideo: boolean = false;
	public isImage: boolean = false;
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public metaFieldSetting = undefined;
	constructor(
		private requestService: RequestService,
		private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ModalCarplateViewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		console.log('ViewDialogData', data);
	}

	ngOnInit() {
		this.buildSetting();
	}
	private buildSetting() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.getMetaData(this.data.dataType, undefined, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
				}
				this.loading = false;
				if (data) {
					this.data.modalSetting = data.results;
					this.metaFieldSetting = this.buildMetaSetting(data.results, parent = undefined);
					console.log('this.metaFieldSetting', this.metaFieldSetting);
					if (this.data.data.hasOwnProperty('_id')) {
						this.loadData();
					}
				} else {
					this.layoutUtilsService.showNotification('Something is Wrong', 'Dismiss');
				}
			});
		}
	}
	buildMetaSetting(data, parent = undefined) {
		let dataObject = {};
		// let tabObject = [];
		for (let col of data.fields) {
			if ((col.editable || !col.generated || col.name === 'status') && col.type !== 'object' && col.type !== 'product' && col.type !== 'table') {
				if (parent) {
					col['inputName'] = parent + col['name'];
				}
				dataObject[col.name] = col;
			} else if (col.type === 'object' || col.type === 'product') {
				dataObject[col.name] = this.buildMetaSetting(col);
				// tabObject.push({name: col.name, displayName: col.displayName, fields: this.buildMetaSetting(col, col.name)});
			}
			else if (col.type === 'table') {
				// dataObject[col.name] = this.buildMetaSetting(col);
				// tabObject.push({name: col.name, displayName: col.displayName, fields: this.buildMetaSetting(col, col.name)});
				dataObject[col.name] = col;
			}
		}
		// this.metaFieldTabsSetting = tabObject;
		return dataObject;
	}
	public loadData() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.getSingleData(this.data.dataType, this.data.data['_id'], (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
				}
				if (data) {
					this.analyzeData(data.results);
					this.data.data = data.results;
				}
				this.loading = false;
			});
		}
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
	analyzeData(data): void {
		if (data.filename) {
			if (this.checkIsImage(data.filename)) {
				this.isImage = true;
			} else if (this.checkIsVideo(data.filename)) {
				this.isVideo = true;
			}
		}
	}
	checkIsImage(filePath) {
		const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']; // Add more extensions if needed
		const extension = filePath.slice((filePath.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
		return imageExtensions.includes(extension);
	}
	checkIsVideo(filePath) {
		const videoExtensions = ['mp4', 'avi', 'mov', 'mkv', 'wmv', 'flv']; // Add more extensions if needed
		const extension = filePath.slice((filePath.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
		return videoExtensions.includes(extension);
	}
}
