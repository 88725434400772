import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ResizeService {
    private resizeObserver: ResizeObserver;
    private widthSubject = new BehaviorSubject<number>(window.innerWidth);
    width$ = this.widthSubject.asObservable();

    constructor(private ngZone: NgZone) {
        this.resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                this.ngZone.run(() => {
                    this.widthSubject.next(entry.contentRect.width);
                });
            }
        });
        this.resizeObserver.observe(document.body);
    }
}