import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-google-map-dialog',
    templateUrl: './google-map-dialog.component.html',
    styleUrls: ['./google-map-dialog.component.scss']
})
export class GoogleMapsDialogComponent implements OnInit {
    display: any;
    center: google.maps.LatLngLiteral = {
        lat: 0,
        lng: 0
    };
    zoom = 15;
    options = {
        streetViewControl: false,
        mapTypeControl: true,
        fullscreenControl: false,
        scaleControl: false,
        minZoom: 3,
        disableDoubleClickZoom: true,
        panControl: true,
        clickableIcons: false,
    }
    marker: any = {}
    geocoder: google.maps.Geocoder = new google.maps.Geocoder();

    width: string = '100%';
    height: string = '30vh';
    lat: number = 0;
    lng: number = 0;

    address = '';
    geoLocation = {};
    loading: boolean = false;

    constructor(private translate: TranslateService, public dialogRef: MatDialogRef<GoogleMapsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data) {
            this.height = data.height || this.height;
            this.lat = +data.lat || this.lat;
            this.lng = +data.lng || this.lng;
        }

        if (this.lat && this.lng) {
            this.center = {
                lat: this.lat,
                lng: this.lng
            }
            this.geocodeLatLng(this.geocoder, this.lat, this.lng);

            this.marker = {
                position: this.center,
                info: '',
                options: {
                    animation: google.maps.Animation.DROP,
                },
            }
        }
        else if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position: any) => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }
                this.geocodeLatLng(this.geocoder, position.coords.latitude, position.coords.longitude);

                this.marker = {
                    position: this.center,
                    // label: {
                    //     color: 'blue',
                    //     text: 'Marker label ',
                    // },
                    // title: this.translate.instant('Encampment'),
                    info: '',
                    options: {
                        animation: google.maps.Animation.DROP,
                    },
                }
            }, (error: any) => {

            });
        }
    }

    ngOnInit(): void {
        this.loading = true;
    }

    setPin(event: any) {
        if (!this.data.disablePinClick) {
            this.geocodeLatLng(this.geocoder, event.latLng.lat(), event.latLng.lng());
            this.marker = {
                position: {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                },
                title: this.translate.instant('Encampment'),
                options: {
                    animation: google.maps.Animation.DROP,
                },
            }
        }
    }

    geocodeLatLng(
        geocoder: google.maps.Geocoder, lat: any, lng: any
    ) {
        const latlng = {
            lat: lat,
            lng: lng,
        };

        geocoder
            .geocode({ location: latlng })
            .then((response) => {
                if (response.results[0]) {
                    this.address = response.results[0].formatted_address;
                    this.geoLocation = latlng;
                } else {
                    // window.alert("No results found");
                }
            })
            .catch((e) => {
                // window.alert("Geocoder failed due to: " + e)
            });
    }

    onConfirm() {
        this.dialogRef.close({ address: this.address, coordinates: this.geoLocation });
    }

    loaded() {
        this.loading = false;
    }
}