import { Directive, Input, AfterViewInit, ElementRef } from '@angular/core';

@Directive({
  selector: '[appFocusOnLoad]'
})
export class FocusOnLoadDirective implements AfterViewInit {

  @Input() appFocusOnLoad: boolean;

  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit() {
    if (this.appFocusOnLoad) {
      setTimeout(() => {
        this.elementRef.nativeElement.focus();
      }, 1500)
    }
  }
}