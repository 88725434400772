import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CsvExporterService, LayoutUtilsService, LoaderService, RequestService } from '../../services';
import * as moment from 'moment';
import { Subscription } from 'rxjs/internal/Subscription';
import { sanitizeFilename } from '../../helpers';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

interface ViewDialogData {
	title: string;
	selectedClient: any;
	metaFieldSetting: any;
	users: any[];
	clients: boolean;
}

@Component({
	selector: 'app-scheduler-export-dialog-modal',
	templateUrl: './custom-scheduler-export-dialog.component.html',
	styleUrls: ['./custom-scheduler-export-dialog.component.scss']
})
export class ModalSchedulerExportDialogComponent {
	private subscriptions: Subscription[] = [];
	public selectedUser: any;
	public metaFieldSetting: any;
	public isAdmin: boolean = false;
	public isSuperAdmin: boolean = false;
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public clients: boolean = false;
	public dataVal: string = '';
	public selectedClients: string[] = [];
	public selectedClientsObject: any = {};
	public selectedClient: string = '';
	public selectedCategory: string = 'all';
	public selectedSubCategory: string = 'all';
	public selectedFacility: string = 'all';
	public selectedSAFE: string[] = [];
	public selectedWrna: string[] = [];
	public allSelectedWrna: boolean = true;
	public users: any[] = [];
	public dataType: string = 'event';
	public maxRange: number = 1;
	public actDateFormat: string = 'DD/MM/YYYY';
	public selectedStartDate = undefined;
	public selectedEndDate = undefined;
	public menuIsOpen = false;

	constructor(private zone: NgZone,
		protected layoutUtilsService: LayoutUtilsService, protected translate: TranslateService,
		private requestService: RequestService,
		public dialogRef: MatDialogRef<ModalSchedulerExportDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData, private csvService: CsvExporterService, protected loaderService: LoaderService) {
		// console.log('ModalGalleryDialogComponent', data);

		if (data.hasOwnProperty('selectedClient') && data.hasOwnProperty('users')) {
			this.selectedClient = data.selectedClient;
			this.users = data.users;
			if (data.selectedClient !== 'all') {
				this.selectedClients = [data.selectedClient]
			} else {
				this.selectedClients = data.users.map((itm) => {
					return itm._id;
				});
			}
			for (let itm of data.users) {
				this.selectedClientsObject[itm._id] = itm;
			}
		}
		if (data.hasOwnProperty('clients')) {
			this.clients = data.clients;
		}
		if (data.hasOwnProperty('metaFieldSetting')) {
			this.metaFieldSetting = data.metaFieldSetting;
		}
		// console.log('this.clients', this.clients);
		// console.log('this.users', this.users);
		// console.log('this.selectedClients', this.selectedClients);
		// console.log('this.metaFieldSetting', this.metaFieldSetting);
		let currentStartDateStart = moment().clone().startOf('month');
		let currentEndDateStart = moment().clone().endOf('month');
		this.selectedStartDate = currentStartDateStart.toDate();
		this.selectedEndDate = currentEndDateStart.toDate();
		if (this.clients) {
			this.selectSAFE(['all']);
			this.selectWrna(['all']);
		}

	}
	ngOnInit() {
		this.subscriptions.push(
			this.requestService.currentUserSubject.subscribe((data) => {
				if (data) {
					this.selectedUser = data;
					this.isAdmin = this.requestService.isUserRoleAdmin();
					this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();
					this.buildSetting();
				}
			})
		);
	}
	buildSetting() {
		if (this.selectedSubCategory == 'all' || ['16', '17'].includes(this.selectedSubCategory)) {
			this.metaFieldSetting['facility']['disabled'] = false;
		} else {
			this.selectedFacility = 'all';
			this.metaFieldSetting['facility']['disabled'] = true;
		}
	}
	closeModal(data): void {
		this.zone.run(() => {
			this.dialogRef.close(data);
		});
	}
	onMenuOpened(): void {
		this.menuIsOpen = true;
	}

	onMenuClosed(): void {
		this.menuIsOpen = false;
		let currentStartDate = moment(this.selectedStartDate).startOf('day');
		let currentEndDate = moment(this.selectedEndDate).endOf('day');
		this.selectedStartDate = currentStartDate.toDate();
		this.selectedEndDate = currentEndDate.toDate();
	}
	startMenuClose(event: Event): void {
		// Prevent the click event from propagating up to the MatMenu
		event.stopPropagation();
	}
	endMenuClose(event: Event): void {
		// Prevent the click event from propagating up to the MatMenu
		event.stopPropagation();
	}
	startMenuChange(event: Event): void {
		if (this.selectedEndDate >= event) {
			this.selectedStartDate = event;
		} else {
			this.layoutUtilsService.showNotification(this.translate.instant('Start Date should be less than End Date'), this.translate.instant('Dismiss'));
		}
	}
	endMenuChange(event: Event): void {
		if (this.selectedStartDate <= event) {
			this.selectedEndDate = event;
		} else {
			this.layoutUtilsService.showNotification(this.translate.instant('End Date should be greater than Start Date'), this.translate.instant('Dismiss'));
		}
	}
	downloadData() {
		if (!this.loading) {
			this.loading = true;
			this.loaderService.display(true);

			// let mom = moment;
			let currentStartDate = moment(this.selectedStartDate);
			let currentEndDate = moment(this.selectedEndDate);
			// if (this.currentDay) {
			// 	let fullDate = this.getFullDay(this.currentDay);
			// 	currentStartDate = moment(fullDate);
			// 	currentEndDate = moment(fullDate);
			// }

			// let termConfiguration = this.termConfiguration();
			let startFilter = currentStartDate.clone().utc();
			let endFilter = currentEndDate.clone().utc();

			let filters = {
				'foreign_key': { '$eq': this.requestService.orgId },
				'project': { '$eq': environment.projectName },
				'startdate': { '$gte': startFilter.format('YYYY-MM-DDTHH:mm') },
				'enddate': { '$lt': endFilter.format('YYYY-MM-DDTHH:mm') }
			}
			if (this.selectedCategory !== 'all') {
				filters['category'] = { '$eq': this.selectedCategory };
			}
			if (this.selectedSubCategory !== 'all') {
				filters['subcategory'] = { '$eq': this.selectedSubCategory };
			}
			if (this.selectedFacility !== 'all') {
				filters['facility'] = { '$eq': this.selectedFacility };
			}
			if (this.selectedWrna.length > 0) {
				filters['wrna'] = { '$in': [this.selectedWrna] };
			}
			if (this.selectedSAFE.length > 0) {
				filters['safe'] = { '$in': [this.selectedSAFE] };
			}
			filters["$or"] = this.commonFilter();
			let filterObj = { page: 1, perpage: 1000, term: '', orderDir: 'asc', orderBy: 'name', fieldKeys: [], filter: filters };
			this.requestService.getDataList(this.dataType,
				filterObj
				, (data, error) => {
					//console.log(data);
					if (error) {
						this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
						this.loaderService.display(false);
						this.loading = false;
					}
					if (data) {
						let dataList = data.results || [];
						this.downloadCSV(dataList);
						this.loaderService.display(false);
						this.loading = false;
					}
				});
		}
	}
	commonFilter() {
		if (this.clients) {
			return [
				// {
				// 	"createdBy": { "$in": this.selectedClients }
				// },
				{
					"$and": [
						{
							"attendees._id": { "$in": this.selectedClients }
						}
					]
				}
			]
		} else {
			return [
				{
					"$and": [
						{ "attendees": { "$size": 0 } },
						{
							"createdBy": { "$eq": this.selectedUser._id }
						}
					]
				},
				{
					"$and": [
						{ "attendees._id": this.selectedUser._id },
						{
							"createdBy": { "$ne": this.selectedUser._id }
						}
					]
				}
			]
		}
	}
	getEmptyCsvLine() {
		let csvLine: any = {
		}
		if (this.clients) {
			csvLine['client_id'] = '';
			csvLine['birth_year'] = '';
			csvLine['age_started'] = '';
		}
		csvLine['date'] = '';
		csvLine['start_time'] = '';
		csvLine['end_time'] = '';
		csvLine['recurrence'] = '';
		csvLine['title'] = '';
		csvLine['type(online / in-person)'] = '';
		csvLine['details(location / meeting url)'] = '';
		csvLine['main_category'] = '';
		csvLine['subcategory'] = '';
		csvLine['facility'] = '';
		csvLine['participation nb'] = '';
		csvLine['description notes'] = '';
		return csvLine;
	}
	getDataCsvLine(itm) {
		let csvLine: any = this.getEmptyCsvLine()
		if (this.clients) {
			csvLine['client_id'] = itm.client_id;
			csvLine['birth_year'] = itm.birth_year;
			csvLine['age_started'] = itm.age_started;
		}
		csvLine['date'] = moment(itm.startdate).format(this.actDateFormat);
		csvLine['start_time'] = moment(itm.startdate).format('hh:mm A');
		csvLine['end_time'] = moment(itm.enddate).format('hh:mm A');
		csvLine['recurrence'] = itm.isRecurrence ? 'Yes' : 'No';
		csvLine['title'] = itm.name;
		csvLine['type(online / in-person)'] = this.getDisplayName(this.metaFieldSetting['meetingType'].enum, 'value', 'displayName', itm.meetingType);
		csvLine['details(location / meeting url)'] = itm.meetingType === 1 ? itm.meetingurl : itm.location;
		csvLine['main_category'] = this.getDisplayName(this.metaFieldSetting['category'].enum, 'value', 'displayName', itm.category);
		csvLine['subcategory'] = this.getDisplayName(this.metaFieldSetting['subcategory'].enum, 'value', 'displayName', itm.subcategory);
		csvLine['facility'] = this.getDisplayName(this.metaFieldSetting['facility'].enum, 'value', 'displayName', itm.facility);
		csvLine['participation nb'] = itm.partno;
		csvLine['description notes'] = itm.description;
		return csvLine;
	}
	public getDisplayName(lst: any[], idKey: string = '_id', idName: string = 'name', value: string = '') {
		for (let itm of lst) {
			if (itm[idKey] === value) {
				return itm[idName];
			}
		}
		return undefined;
	}
	public downloadCSV(dataList) {
		const items: any[] = [];
		let csvLine: any = this.getEmptyCsvLine();

		if (dataList.length > 0) {
			for (let itm of dataList) {
				if (this.clients && itm.attendees && itm.attendees.length > 0) {
					for (let client of itm.attendees) {
						if (this.selectedClientsObject[client._id]) {
							itm['client_id'] = this.selectedClientsObject[client._id]['client_id'] || '';
							let birth_year = '';
							let age_started = '';
							if (this.selectedClientsObject[client._id]['dob']) {
								let dateDOB = moment(this.selectedClientsObject[client._id]['dob']).local();
								let year = dateDOB.year().toString();
								birth_year = year || '';
								if (this.selectedClientsObject[client._id]['date_started']) {
									let dateStarted = moment(this.selectedClientsObject[client._id]['date_started']).local();
									age_started = dateStarted.diff(dateDOB, 'years').toString();
								}

							}
							itm['birth_year'] = birth_year
							itm['age_started'] = age_started;
						}
						csvLine = this.getDataCsvLine(itm);
						items.push(csvLine);
					}
				} else {
					csvLine = this.getDataCsvLine(itm);
					items.push(csvLine);
				}
			}
		} else {
			items.push(csvLine);
		}

		let date = new Date();
		let formattedDate = moment(date).format('YYYYMMDDHHmm');
		// let clientName = '';
		// if (this.data.selectedClient !== 'all') {
		// 	let clientDataList = this.users.filter((itm) => {
		// 		return itm._id === this.data.selectedClient;
		// 	})
		// 	if (clientDataList.length > 0) {
		// 		clientName = clientDataList[0].name
		// 	}
		// } else {
		// 	clientName = 'all_clients'
		// }
		let fileName = sanitizeFilename(formattedDate + '_' + this.data.selectedClient + ".csv");
		this.csvService.exportToCsv(fileName, items);
		this.closeModal(undefined);
	}
	selectWrna(value) {
		let selectedWrna = this.analyzeMultiple(this.selectedWrna, value, this.metaFieldSetting['wrna'].enum);
		this.selectedWrna = selectedWrna;
	}
	selectSAFE(value) {
		let selectedSAFE = this.analyzeMultiple(this.selectedSAFE, value, this.metaFieldSetting['safe'].enum);
		this.selectedSAFE = selectedSAFE;
	}
	// analyzeMultiple(preValue, value, dataOptions) {
	// 	let selectedData = [];
	// 	if (value.includes('all')) {
	// 		if (preValue.length === dataOptions.length) {
	// 			selectedData = [];
	// 		} else {
	// 			selectedData = dataOptions.map((itm) => {
	// 				return itm.value;
	// 			});
	// 		}
	// 	} else {
	// 		selectedData = value;
	// 	}
	// 	return selectedData;
	// }
	analyzeMultiple(preValue, value, dataOptions) {
		let isAll = preValue.includes('all');
		let selectedData = [];
		if (isAll) {
			if (!value.includes('all')) {
				selectedData = [];
			} else {
				selectedData = value.filter((item) => {
					return item !== 'all';
				});
			}
		} else {
			if (value.includes('all')) {
				selectedData = dataOptions.map((itm) => {
					return itm.value;
				});
				selectedData.push('all');
			} else {
				selectedData = value;
			}
		}
		return selectedData;
	}
}
