<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<div class="ImageTabContainer" *ngIf="data.data">
			<img style="max-width: 100%;" src="{{data.data}}">
		</div>
	</div>
	<div mat-dialog-actions>
		<span>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">Close</button>
		</span>
	</div>
</div>