import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enum'
})
export class EnumPipe implements PipeTransform {

    transform(value: string, enums: any): string {

        if (enums == undefined || !value) {
            return '';
        }

        return enums.find((i: any) => i.value === value)?.displayName;
    }

}
