import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, args: string[]): string {
    let limit = args.length > 0 ? parseInt(args[0], 10) : 10;
    let trail = args.length > 1 ? args[1] : '...';
    if (!value) {
      return '';
    } else {
      return value.length > limit ? value.substring(0, limit) + trail : value;
    }
  }
}
export const truncatePipeInjectables: any[] = [
  TruncatePipe
];