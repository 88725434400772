import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface ChatData {
    show: boolean;
    caseId: string;
    caseName: string;
    investigators: any;
    investigatorGroups: any;
    isInvestigatorProfile?: boolean;
}

export interface CallData {
    show: boolean;
    caseId?: string;
    investigators?: any;
    selectedUsers?: any;
    invitedBy?: string;
    callerId?: string;
    investigatorGroups?: any;
    isInvestigatorProfile?: boolean;
}

@Injectable()
export class ChatService {

    public selectedUser: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public showChatDialogSubject: BehaviorSubject<ChatData> = new BehaviorSubject<ChatData>({
        show: undefined,
        caseId: '',
        caseName: '',
        investigatorGroups: [],
        investigators: [],
    });
    public showCallDialogSubject: BehaviorSubject<CallData> = new BehaviorSubject<CallData>({
        show: false
    });
    public ongoingCall: boolean = false;
}