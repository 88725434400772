import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {
    constructor(private translate: TranslateService) {
        super(); // Call the base class constructor

        // Set your translated labels here
        this.itemsPerPageLabel = this.translate.instant('paginator.itemsPerPage');
        this.nextPageLabel = this.translate.instant('paginator.nextPage');
        this.previousPageLabel = this.translate.instant('paginator.previousPage');
        this.lastPageLabel = this.translate.instant('paginator.lastPage');
        this.firstPageLabel = this.translate.instant('paginator.firstPage');
        // ... other labels ...

        // Override the getRangeLabel method to handle translated range labels
        this.getRangeLabel = (page: number, pageSize: number, length: number) => {
            const start = page * pageSize + 1;
            const end = Math.min((page + 1) * pageSize, length);
            return this.translate.instant('paginator.rangeLabel', { start, end, length });
        };
    }
}