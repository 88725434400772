import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, Renderer2, ViewChildren, QueryList } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Moment } from 'moment';
import * as moment from 'moment';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

class My2ErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-custom-startenddatetime',
  templateUrl: './custom-startenddatetime.component.html',
  styleUrls: ['./custom-startenddatetime.component.scss']
})
export class CustomStartEndDateTimeComponent implements OnInit {
  public esMatcher = new My2ErrorStateMatcher();
  public timeArray = [];
  public allDay: boolean = false;
  public dateStartValue: string = moment(new Date().toISOString()).format('YYYY-MM-DD');
  public timeStartValue: string = moment(new Date().toISOString()).format('HH:mm');
  public dateEndValue: string = moment(new Date().toISOString()).format('YYYY-MM-DD');
  public timeEndValue: string = moment(new Date().toISOString()).format('HH:mm');
  @Input() metadata: any = undefined;

  public _value: any = undefined;
  @Input()
  set value(value: any) {
    this._value = value;
    if (value) {
      if (value.hasOwnProperty('startdate')) {
        let momentValue = moment(value.startdate);
        this.dateStartValue = momentValue.format('YYYY-MM-DD');
        this.timeStartValue = momentValue.format('HH:mm');
      }
      if (value.hasOwnProperty('enddate')) {
        let momentValue = moment(value.enddate);
        this.dateEndValue = momentValue.format('YYYY-MM-DD');
        this.timeEndValue = momentValue.format('HH:mm');
      }
      if (value.allday) {
        this.allDay = value.allday;
      }
    }
  }
  get value(): any {
    return this._value;
  }

  @Output() dataChange = new EventEmitter<any>();
  @ViewChild('dataForm') form: NgForm;
  @ViewChild('autoStart') autocompleteStart: MatAutocompleteTrigger;
  @ViewChildren('matOptionStart') matOptionsStart: QueryList<ElementRef>;
  @ViewChild('autoEnd') autocompleteEnd: MatAutocompleteTrigger;
  @ViewChildren('matOptionEnd') matOptionsEnd: QueryList<ElementRef>;
  constructor(private renderer: Renderer2) {
    // if(this.form)
    //  this.form.onSubmit(undefined);

    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 30) {
        let hour = (i % 12 === 0) ? 12 : i % 12;
        let minute = j === 0 ? '00' : j;

        let ampm = i < 12 ? 'AM' : 'PM';

        let displayTime = hour + ':' + minute + ' ' + ampm;
        let valueTime = (i < 10 ? '0' + i : i) + ':' + minute;

        this.timeArray.push({ display: displayTime, value: valueTime });
      }
    }
  }

  ngOnInit() {
  }
  setupData() {

  }
  public setAllDayAttribute(val) {
    if (val === this.metadata['allday']['applyChange'].value) {
      this.timeStartValue = this.metadata['allday']['applyChange'].starttime;
      this.timeEndValue = this.metadata['allday']['applyChange'].endtime;
      if (this.dateStartValue && (this.dateStartValue === 'Invalid date' || this.dateStartValue === '')) {
        this.dateStartValue = moment(new Date().toISOString()).format('YYYY-MM-DD');
      }
      if (this.dateEndValue && (this.dateEndValue === 'Invalid date' || this.dateEndValue === '')) {
        this.dateEndValue = moment(new Date().toISOString()).format('YYYY-MM-DD');
      }
    }
    this.dataChange.emit([
      { id: 'allday', value: val },
      { id: 'startdate', value: moment(this.dateStartValue + 'T' + this.timeStartValue).format('YYYY-MM-DDTHH:mm') },
      { id: 'enddate', value: moment(this.dateEndValue + 'T' + this.timeEndValue).format('YYYY-MM-DDTHH:mm') }]);
  }
  public setDateStartAttribute(val) {
    let selectedDate = new Date(val);

    // Get the year from the selected date
    let year = selectedDate.getFullYear();
    // Check if the year has more than 4 digits
    if (year.toString().length > 4) {
      this.dateStartValue = '';
    } else {
      this.dataChange.emit([{ id: 'startdate', value: moment(val + 'T' + this.timeStartValue).format('YYYY-MM-DDTHH:mm') }]);
    }
  }
  onStartOpened() {
    setTimeout(() => {
      const optionToFocus: any = this.matOptionsStart.find(option => option['value'] === this.timeStartValue);
      if (optionToFocus) {
        optionToFocus.focus();
      }
    }, 50);
  }
  onEndOpened() {
    setTimeout(() => {
      const optionToFocus: any = this.matOptionsEnd.find(option => option['value'] === this.timeEndValue);
      if (optionToFocus) {
        optionToFocus.focus();
      }
    }, 50);
  }
  public setTimeStartAttribute(val, type) {
    if (this.dateStartValue && (this.dateStartValue === 'Invalid date' || this.dateStartValue === '')) {
      this.dateStartValue = moment(new Date().toISOString()).format('YYYY-MM-DD');
    }
    if (val && val !== '') {
      this.dataChange.emit([{ id: 'startdate', value: moment(this.dateStartValue + 'T' + val).format('YYYY-MM-DDTHH:mm') }]);
    } else {
      this.dataChange.emit([{ id: 'startdate', value: moment(this.dateStartValue + 'T00:00').format('YYYY-MM-DDTHH:mm') }]);
    }
  }
  public setDateEndAttribute(val) {
    let selectedDate = new Date(val);

    // Get the year from the selected date
    let year = selectedDate.getFullYear();
    // Check if the year has more than 4 digits
    if (year.toString().length > 4) {
      this.dateEndValue = '';
    } else {
      this.dataChange.emit([{ id: 'enddate', value: moment(val + 'T' + this.timeEndValue).format('YYYY-MM-DDTHH:mm') }]);
    }
  }
  public setTimeEndAttribute(val, type) {
    if (this.dateEndValue && (this.dateEndValue === 'Invalid date' || this.dateEndValue === '')) {
      this.dateEndValue = moment(new Date().toISOString()).format('YYYY-MM-DD');
    }
    if (val && val !== '') {
      this.dataChange.emit([{ id: 'enddate', value: moment(this.dateEndValue + 'T' + val).format('YYYY-MM-DDTHH:mm') }]);
    } else {
      this.dataChange.emit([{ id: 'enddate', value: moment(this.dateEndValue + 'T00:00').format('YYYY-MM-DDTHH:mm') }]);
    }
  }
  // this.returnMarginValue.emit(this.marginValue);
  public onSubmit() {
    // do nothing
  }
}
