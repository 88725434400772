import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import * as OT from '@opentok/client';
import { TranslateService } from "@ngx-translate/core";
import { RequestService } from "./request.service";
import { LayoutUtilsService } from "./utils/layout-utils.service";
import { DeviceDetectorService } from 'ngx-device-detector';

export interface ChatData {
    show: boolean;
    caseId?: string;
    caseName?: string;
    investigators?: any;
    investigatorgroups?: any;
}

@Injectable()
export class VonageService {

    private pauseDialog: any = undefined;

    constructor(private layoutService: LayoutUtilsService, private requestService: RequestService, private translate: TranslateService, private deviceService: DeviceDetectorService) { }

    getOT(): any {
        if (environment.environment === 'prod')
            OT.setLogLevel(0);
        return OT;
    }

    initSession(sessionId: string) {
        if (environment.opentok) {
            let session = this.getOT().initSession(environment.opentok, sessionId);
            return Promise.resolve(session);
        }
    }

    connect(token: string, session: OT.Session) {
        //console.log(token);
        return new Promise((resolve, reject) => {
            session.connect(token, (error) => {
                if (error) {
                    console.log(error, session)
                    this.handleError(error);
                    //reject(error);
                } else {
                    resolve(session);
                }
            });
        });
    }

    public unSubscribe(subscriber: OT.Subscriber, session: OT.Session) {
        try {
            if (subscriber && subscriber.stream) {
                session.unsubscribe(subscriber);
                subscriber.off();
            }
        }
        catch (e) {
            // console.log('while unsubscribing', subscriber)
        }
    }

    public disconnectSession(session: OT.Session) {
        try {
            if (session) {
                session.off();
                session.disconnect();
            }
        }
        catch (e) {

        }
    }

    public handleError(errorCode: Object) {
        //console.log(errorCode);
        switch (errorCode['name']) {
            case 'OT_AUTHENTICATION_ERROR':
                if (errorCode['code'] == 1004) {
                    this.layoutService.showNotificationSnack('Authentication error. Refresh the page.', 'Dismiss');
                }
                else {
                    this.layoutService.showNotificationSnack('Authentication error.', 'Dismiss');
                }
                break;
            case 'OT_SCREEN_SHARING_NOT_SUPPORTED':
                this.layoutService.showNotificationSnack('Unable to share screen.', 'Dismiss');
                break;
            case 'OT_CREATE_PEER_CONNECTION_FAILED':
                this.layoutService.showNotificationSnack('Client unable to join session.', 'Dismiss');
                break;
            case 'OT_SCREEN_SHARING_NOT_SUPPORTED':
                this.layoutService.showNotificationSnack('Screen sharing is not supported.', 'Dismiss');
                break;
            case 'OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED':
            case 'OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED':
                this.layoutService.showNotificationSnack('Screen sharing requires a type extension, but it is not installed.', 'Dismiss');
                break;
            case 'OT_USER_MEDIA_ACCESS_DENIED':
                this.layoutService.showNotificationSnack('Allow access and try publishing again.', 'Dismiss');
                break;
            case 'OT_NO_DEVICES_FOUND':
                this.layoutService.showNotificationSnack('You do not have a microphone attached to your computer.', 'Dismiss');
                break;
            case 'OT_NOT_CONNECTED':
                this.layoutService.showNotificationSnack('Session is not connected yet.', 'Dismiss');
                break;
            case 'OT_SOCKET_CLOSE_TIMEOUT':
            case 'OT_UNEXPECTED_SERVER_RESPONSE':
                this.layoutService.showNotificationSnack('No connection. Reloading the page.', 'Dismiss');
                setTimeout(() => window.location.reload(), 5000);
                break;
            case 'OT_HARDWARE_UNAVAILABLE':
                this.layoutService.showNotificationSnack('Audio/Video hardware is not available.', 'Dismiss');
                break;
            case 'OT_STREAM_DESTROYED':
                break;
            default:
                this.handleErrorByCode(errorCode);
                break;
        }
    }

    public handleErrorByCode(errorCode: Object) {
        switch (errorCode['code'] + '') {
            case '1013':
                this.layoutService.showNotificationSnack('Blocked by firewall', 'Dismiss');
                break;
            case '1004':
                this.layoutService.showNotificationSnack('Authentication error, Token is invalid. ', 'Dismiss');

                break;
            case '1005':
                this.layoutService.showNotificationSnack('Authentication error, Session is invalid.', 'Dismiss');
                break;
            case '1006':
                this.layoutService.showNotificationSnack('Connection failed, check if you have internet connection.', 'Dismiss');
                break;
            case '1010':
                this.layoutService.showNotificationSnack('Cannot publish: the client is not connected to the session.', 'Dismiss');
                break;
            case '1500':
                this.layoutService.showNotificationSnack('Unable to Publish.', 'Dismiss');
                break;
            case '1501':
                this.layoutService.showNotificationSnack('Slow internet connection.', 'Dismiss');
                break;
            case '1510':
                this.layoutService.showNotificationSnack('Unable to send message.', 'Dismiss');
                break;
            case '1004':
                this.layoutService.showNotificationSnack('Authentication error. Check token expiry.', 'Dismiss');
                break;
            default:
                // this.layoutService.showNotificationSnack('Slow connection.', 'Dismiss');
                break;
        }
    }

    promptPauseDialog() {
        if (!((this.deviceService.isTablet() || this.deviceService.isMobile()) && this.deviceService.getDeviceInfo().browser == 'Safari')) {
            if (!this.pauseDialog) {
                let startTimer = 0;
                let startTimerInterval = setInterval(() => {
                    startTimer++;
                }, 1000);

                this.pauseDialog = this.layoutService.alertActionElement('', this.translate.instant('Please unmute your computer audio if it is muted and click here to proceed.'), {
                    overlayClickToClose: false,
                    showCancelButton: false,
                    declineText: this.translate.instant('Proceed')
                }, 'fit-content');
                this.pauseDialog.afterClosed().subscribe((res) => {
                    clearInterval(startTimerInterval);
                    if (OT)
                        OT.unblockAudio();
                    this.pauseDialog = undefined;
                });
            }
        }
    }

    public sendSignal(type: string, message: string, session: OT.Session, recepientConnection?: OT.Connection) {
        if (message && message.trim() != '' && session && session['isConnected']()) {
            let signalData;
            if (recepientConnection) {
                signalData = {
                    to: recepientConnection,
                    data: message,
                    type: type
                }
            }
            else {
                signalData = {
                    data: message,
                    type: type
                }
            }
            return new Promise((resolve, reject) => session.signal(signalData, (error) => {
                if (error) {
                    reject();
                } else {
                    resolve(true);
                }
            }));
        }
    }
}