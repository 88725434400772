export const environment = {
  production: false,
  serverLoginUrl: 'https://staging.api.auth.interactivelife.me/api/',
  serverMainUrl: 'https://staging.cipher.interactivelife.me/',
  serverUrl: 'https://staging.api.cipher.interactivelife.me/api/',
  scheduleUrl: 'https://staging.api.cipher.interactivelife.me/api/',
  serverName: 'Cipher',
  aliveAppIntervalMinutes: 30, // if you keep app inactive
  aliveCheckAppIntervalMinutes: 60, // if you keep app inactive pop a message
  subServerName: '',
  lang: 'en',
  productId: '40095093-5ee8-44eb-b92a-68cb5ae9d04c',
  projectName: 'cipher',
  profileImageURL: '',
  orgType: 'cipher',
  orgId: '65afbb77ecac8e05c82aff5d',
  appId: '65afbbc4ecac8e05c82aff63',
  locId: '65afbbfbecac8e05c82aff67',
  serverHostUrl: 'https://staging.cipher.interactivelife.me/',
  customKeys: {
    roleAdmin: '5eb137f1d3123a407c7c97cc',
    roleInvestigators: '64dc639a4096f85368c00770',
    rolePrefectureCommanders: '64dc69d44096f85368c00776',
    roleRegionCommanders: '65b0a7ddecac505ef2f994d8',
    roleTechnicians: '65b0a7faecac505ef2f994da',
  },
  customKeysView: {
    '5eb137f1d3123a407c7c97cc': 'Admin',
    '64dc639a4096f85368c00770': 'Investigators',
    '64dc69d44096f85368c00776': 'Prefecture Commanders',
    '65b0a7ddecac505ef2f994d8': 'Region Commanders',
    '65b0a7faecac505ef2f994da': 'Technicians',
  },
  rollbar: {
    apiKey: '9c5ba84789c04a4db6bd4343ee519c4c'
  },
  rolesCanAccess: ['5eb137f1d3123a407c7c97cc', '64dc69d44096f85368c00776', '65b0a7ddecac505ef2f994d8', '64dc639a4096f85368c00770'],
  firebaseVapidKey: 'BCyEHXaQoHzGynRBA5eUtds5fCYj-wwBzc_WlLihvUOFpGj7KxftIZEDpI_NljFYizew_modfAt2m8sh7eJEtJU',
  firebaseConfig: {
    apiKey: "AIzaSyCwaHp92H6hIO5Vmoa4AUPizJdlK5Zdn6g",
    authDomain: "cipher-29d8a.firebaseapp.com",
    projectId: "cipher-29d8a",
    storageBucket: "cipher-29d8a.appspot.com",
    messagingSenderId: "479483185915",
    appId: "1:479483185915:web:03a2cb2431a46fb1ba7e5e",
    measurementId: "G-4K7ED1ZB9E",
    databaseURL: "https://cipher-29d8a-default-rtdb.firebaseio.com"
  },
  opentok: '47855021',
  firestore_config: {
    channelsDB: 'channels',
    notificationDb: 'notification',
  },
  environment: 'staging',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
