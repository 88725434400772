import { Injectable, NgZone } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { LoaderService } from "./loader.service";
import { RequestService } from "./request.service";
import { LayoutUtilsService } from "./utils/layout-utils.service";
import { Router } from '@angular/router';
import { DeviceDetectorService } from "ngx-device-detector";
import * as Ably from 'ably';
import { BehaviorSubject } from "rxjs";

@Injectable()
export class AblyService {

    public ably: any = undefined;
    public timelineTopicName: string = 'note';
    public isConnected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private connectedChannels: any = [];
    private dialog: any = undefined;
    private env: any = 'local';

    constructor(private requestService: RequestService, private zone: NgZone, private layoutUtilsService: LayoutUtilsService, private translate: TranslateService, private loadService: LoaderService, private router: Router, private deviceService: DeviceDetectorService) { }

    init() {
        if (typeof Ably != 'undefined' && !this.ably) {
            this.env = environment.environment == 'local' ? 'staging' : environment.environment;
            this.ably = new Ably.Realtime({
                authCallback: async (tokenParams, callback) => {
                    try {
                        this.requestService.getRequest('user/ably/auth', '', (data, error) => {
                            if (data) {
                                callback(null, data.results)
                            }
                        });
                    } catch (error) {
                        callback(error, null)
                    }
                },
                recover: (lastConnectionDetails, cb) => {
                    if (this.requestService.isNetworkConnected) {
                        cb(true);
                    } else {
                        cb(false);
                    }
                }
            });

            this.ably.connection.on("connected", () => {
                // console.log("Connected to Ably!")
                this.isConnected.next(true);
            });

            this.ably.connection.on("connecting", () => {
                // console.log("connecting")
            });

            this.ably.connection.on("disconnected", (e) => {
                // console.log("disconnected", e)
            });

            this.ably.connection.on("closed", () => {
                console.log("ably connection closed")
            });

            this.ably.connection.on('failed', () => {
                this.showAblyErrorDialog();
            });

            this.ably.connection.on('suspended', () => {
                this.showAblyErrorDialog();
            });

            // this.getOwnChannel({ _id: '663b6d123064423a1985ee8a' });
        }
    }

    getChannel(name: string = this.timelineTopicName) {
        let channelName = `${this.env}:${this.requestService.orgId}:${name}`;
        // console.log('channelName', channelName)
        let channelIdx = this.connectedChannels.findIndex(i => i.name === channelName);
        if (channelIdx == -1) {
            let channel = this.ably.channels.get(channelName);
            channel.on('detached', (error) => {
                console.error('Channel detached:', error);
                // this.handleChannelError(error);
            });
            channel.on('failed', (error) => {
                console.error('Channel failed:', error);
                //   this.handleChannelError(error);
            });
            channel.on('disconnected', (error) => {
                console.error('Channel disconnected:', error);
                //   this.handleChannelError(error);
            });
            this.connectedChannels.push(channel);

            // let existingTopics = JSON.parse(localStorage.getItem(this.topicName)) || [];
            // if (!existingTopics.find(i => i == channelName)) {
            //     existingTopics.push(channelName);
            //     localStorage.setItem(this.topicName, JSON.stringify(existingTopics));
            // }

            return channel;
        }
        else {
            return this.connectedChannels[channelIdx];
        }
    }

    unsubscribe(topic: string, channelName: string) {
        this.getChannel(channelName).unsubscribe(topic);
    }

    leaveChannel(name: string) {
        let channelName = `${this.env}:${this.requestService.orgId}:${name}`;
        let channel = this.connectedChannels.find(i => i.name === channelName);
        if (channel) {
            channel.detach();
            this.connectedChannels = this.connectedChannels.filter(i => i.name !== channelName);
        }

        // let existingTopics = JSON.parse(localStorage.getItem(this.topicName)) || [];
        // existingTopics = existingTopics.filter(i => i != channelName);
        // localStorage.setItem(this.topicName, JSON.stringify(existingTopics));
    }

    // unSubscribeAll() {
    //     let existingTopics = JSON.parse(localStorage.getItem(this.topicName)) || [];
    //     existingTopics.forEach(topic => {
    //         this.leaveChannel(topic);
    //     });
    //     localStorage.removeItem(this.topicName);
    // }

    publish(messageName: string, payload: any, channelName: string, callBack?: any) {
        let channel = this.getChannel(channelName);
        if (channel) {
            channel.publish(messageName, payload, (error) => {
                if (error) {
                    console.log('error publishing ably', error);
                }
                if (callBack)
                    callBack();
            });
        }
    }

    subscribe(messageName: string, channelName: string, callBack: (message) => void) {
        let channel = this.getChannel(channelName);
        if (channel) {
            channel.subscribe(messageName, (message) => {
                // console.log(message);
                try {
                    callBack(JSON.parse(message.data));
                }
                catch (e) {
                    console.error(e);
                }
            }, (error) => {
                if (error) {
                    if (error.code == '90007') {
                        this.showAblyErrorDialog();
                    }
                }
            });
        }
    }

    getStatus(callback: (status: any | undefined) => void) {
        if (this.ably) {
            if (this.ably.connection && this.ably.connection.state === 'connected') {
                callback('connected');
            } else {
                this.ably.connection.on('connected', () => {
                    callback('connected');
                });
            }
        } else {
            callback('failed');
        }
    }

    private showAblyErrorDialog() {
        if (!this.dialog) {
            this.zone.run(() => {
                this.loadService.display(false);
                this.dialog = this.layoutUtilsService.alertActionElement('', this.translate.instant('You were idle for a long time or you experienced a connection issue'), {
                    overlayClickToClose: false,
                    showCancelButton: false,
                    declineText: this.translate.instant('Refresh')
                }, 'fit-content');
                this.dialog.afterClosed().subscribe((res) => {
                    if (res != undefined) {
                        window.location.reload();
                    }
                });
            });
        }
    }

    close() {
        try {
            this.ably.close();
        }
        catch (e) { }

        this.ably = undefined;
    }
}
