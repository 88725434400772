import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutSideDirective {

  constructor(private _elementRef: ElementRef) { }

  @Output('clickOutside') clickOutside: EventEmitter<any> = new EventEmitter();

  @HostListener('document:click', ['$event.target']) onMouseEnter(targetElement) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    const clickedInsideParent = targetElement.parentNode;
    if (!clickedInside && !targetElement.hasAttribute('exceptclick') && ((clickedInsideParent && (typeof clickedInsideParent.hasAttribute === 'function' && !clickedInsideParent.hasAttribute('exceptclick'))) || !clickedInsideParent)) {
      this.clickOutside.emit(null);
    }
  }
}
