import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, Renderer2, ViewChildren, QueryList } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Moment } from 'moment';
import * as moment from 'moment';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


class My2ErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-custom-video',
  templateUrl: './custom-video.component.html',
  styleUrls: ['./custom-video.component.scss']
})
export class CustomVideoComponent implements OnInit {
  public iframe_html: SafeResourceUrl = undefined;
  public cameraType = undefined;
  @Input() name: any = '';
  @Input() picture: any = '';
  public _value: any = undefined;
  @Input()
  set value(value: any) {
    this._value = value;
    if (value && value.startsWith('rtsp')) {
      this.cameraType = 'rtsp';
    } else if (value && value.indexOf('vimeo.com') > -1) {
      this.cameraType = 'vimeo';
      this.prepareIframeUrl();
    } else if (value && value.indexOf('youtube.com') > -1) {
      this.cameraType = 'youtube';
      this._value = this._value + '&amp;controls=0&amp;autoplay=1&amp;mute=1&amp;disablekb=1&amp;rel=0&amp;loop=1&modestbranding=1';
      this.prepareIframeUrl();
    } else {
      this.cameraType = 'default';
    }
  }
  get value(): any {
    return this._value;
  }

  constructor(private renderer: Renderer2, private sanitizer: DomSanitizer) {

  }

  ngOnInit() {
  }
  prepareIframeUrl() {
    if (this._value) {
      this.iframe_html = this.transform(this._value);
    } else {
      this.iframe_html = this.transform('/empty');
    }
  }
  transform(url) {
    if (this.sanitizer) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }
}