import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {
    public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public statusWithColor: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

    display(value: boolean) {
        setTimeout(() => {
            this.status.next(value);
        }, 100)
    }

    displayAndSetColor(obj: { color: string, display: boolean }) {
        setTimeout(() => {
            this.statusWithColor.next(obj);
        }, 100);
    }
}
