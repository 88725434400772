<form #dataForm="ngForm" (ngSubmit)="onSubmit(dataForm)">
  <mat-form-field class="example-form-field">
    <mat-label
      [class.my-error]="parentFormSubmitted && required && (value === undefined || value.length === 0)">{{placeholder |
      translate}}<span *ngIf="required">*</span></mat-label>
    <mat-chip-grid #chipGrid aria-label="placeholder">
      <mat-chip-row *ngFor="let tg of value" (removed)="remove(tg)" [style]="chipStyle" title="{{tg.name}}">
        {{tg.name | truncate: ['20','...']}}
        <button class="closeBtn" matChipRemove aria-label="'remove ' + tg.name" *ngIf="removable">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-grid>
    <input placeholder="{{'New' | translate}} {{ placeholder  | translate}}..." #tagInput [formControl]="tagCtrl"
      [matAutocomplete]="auto" [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" (closed)="parentFormSubmitted=true">
      <mat-option *ngFor="let tg of filteredTags | async" [value]="{_id: tg._id, name: tg.name}"
        style="display: block;">
        <div style="margin-top: 13px;display: flex;" title="{{tg.name}}">
          {{tg.name | truncate: ['30','...']}}
          <mat-icon *ngIf="canDelete" class="material-icons deleteItem"
            (click)="checkDeleteItem($event, tg)">cancel</mat-icon>
        </div>
      </mat-option>
    </mat-autocomplete>
    <mat-hint align="end" [style]="hintStyle">{{'To create a new item, write down the word and click enter'|
      translate}}</mat-hint>

    <mat-hint align="start" class="my-error"
      *ngIf="showReqHint && parentFormSubmitted && required && (value === undefined || value.length === 0)">
      {{placeholder | translate}} {{'is required' | translate}}
    </mat-hint>
  </mat-form-field>
</form>