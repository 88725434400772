import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { urlSafeBase64Encoding } from '../../../shared/helpers';

interface ViewDialogData {
	dataType: string;
	subDataType: string;
	title: string;
	data: any;
	modalSetting: any;
	confirmData: any;
}

@Component({
	selector: 'app-image-view-dialog-modal',
	templateUrl: './custom-image-view-dialog.component.html',
	styleUrls: ['./custom-image-view-dialog.component.scss']
})
export class ModalImageViewDialogComponent implements OnInit {
	public errorMessage: string = '';
	public isVideo: boolean = false;
	public isImage: boolean = false;
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public metaFieldSetting = undefined;
	constructor(
		public dialogRef: MatDialogRef<ModalImageViewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		console.log('ViewDialogData', data);
	}

	ngOnInit() {

	}
	closeModal(data = undefined): void {
		this.dialogRef.close(data);
	}
}
