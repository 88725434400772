import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';

const routes: Routes = [
    // {
    //     path: '',
    //     loadChildren: './layout/layout.module#LayoutModule',
    //     canActivate: [AuthGuard]
    // },
    {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'case',
        loadChildren: () => import('./pages/case-page/case-page.module').then(m => m.CasePageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'case/map',
        loadChildren: () => import('./pages/case-map-page/case-map.module').then(m => m.CaseMapModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'case/map/:id',
        loadChildren: () => import('./pages/case-map-page/case-map.module').then(m => m.CaseMapModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'loginWithDialog',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    // {
    //     path: 'signup',
    //     loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule)
    // },
    { path: '**', redirectTo: 'cases/list', pathMatch: 'full' }
    // {
    //     path: 'forgotpassword',
    //     loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
    // }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false })],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule { }
