import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MAT_SELECT_CONFIG } from '@angular/material/select';

interface ViewDialogData {
	title: string;
	data: any;
	confirmData: any;
}

@Component({
	selector: 'app-camera-case-assign-dialog-modal',
	templateUrl: './custom-camera-case-assign-dialog.component.html',
	styleUrls: ['./custom-camera-case-assign-dialog.component.scss'],
	providers: [
		{
			provide: MAT_SELECT_CONFIG,
			useValue: { overlayPanelClass: 'caseSelectOverlay' }
		}
	]
})
export class ModalCameraCaseAssignDialogComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public apiCallSubscription: any = undefined;
	public selectedUser: any;
	public isAdmin: boolean = false;
	public loading: boolean = false;
	public caseSelected: any = undefined;
	// public assignedCasesIds: any[] = [];
	public assignedCases: any = {};
	public availableCases: any[] = [];
	public searchVal: string = '';

	orderDir = 'asc';
	orderBy = 'name'; // _id
	orderByList = [{ field: 'name', order: 'asc' }];
	@ViewChild('searchInput') searchInput: ElementRef;
	constructor(private requestService: RequestService, private translate: TranslateService,
		protected layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ModalCameraCaseAssignDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		// console.log('ModalCameraCaseAssignDialogComponent', data);
	}

	ngOnInit() {
		this.subscriptions.push(
			this.requestService.currentUserSubject.subscribe((data) => {
				if (data) {
					this.selectedUser = data;
					this.isAdmin = this.requestService.isUserRoleAdmin();
					this.loadCameraCasesData((dataResultAssignedCasess) => {
						// this.assignedCasesIds = dataResultAssignedCasess.map((itm) => itm._id);
						this.assignedCases = Object.fromEntries(dataResultAssignedCasess.map(key => [key._id, '']));
						this.loadCasesData((dataResultCasess) => {
							this.availableCases = dataResultCasess;

						});
					});
				}
			})
		);
	}
	selectCase(data) {
		console.log('selectCase', data);
	}
	onKey(ev) {
		this.loadCasesData((dataResultCasess) => {
			this.availableCases = dataResultCasess;
		});
	}
	loadCameraCasesData(callback) {
		let dataCasesResult = [];
		if (this.loading && this.apiCallSubscription) {
			this.apiCallSubscription.unsubscribe();
			this.loading = false;
		}
		if (!this.loading) {
			this.loading = true;
			let fieldsConfiguration = ['case_id'];
			let filterConfiguration = {
			}
			filterConfiguration['$and'] = [
				{ "organizationId._id": { "$in": [this.requestService.orgId] } },
				{ "cameras._id": { "$in": [this.data.data] } }
			];
			let filterObj = { page: 1, perpage: 1000000, orderDir: this.orderDir, orderBy: this.orderBy, orderByList: this.orderByList, filter: filterConfiguration, fieldKeys: fieldsConfiguration };
			this.apiCallSubscription = this.requestService.getDataList('case', filterObj, (data, error) => {
				if (error) {
					this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
				}
				if (data) {
					let dataResult = data.results;
					dataCasesResult = JSON.parse(JSON.stringify(dataResult));
				}
				this.loading = false;
				if (callback) {
					callback(dataCasesResult)
				}
			});
		}
	}

	termConfiguration(): any {
		if (this.searchInput) {
			const searchText: string = this.searchInput.nativeElement.value;
			return searchText;
		}
		return '';
	}
	loadCasesData(callback) {
		let dataCasesResult = [];
		if (this.loading && this.apiCallSubscription) {
			this.apiCallSubscription.unsubscribe();
			this.loading = false;
		}
		if (!this.loading) {
			this.loading = true;
			let fieldsConfiguration = ['case_id'];
			let filterConfiguration = this.getCasesfilter();
			let termConfiguration = this.termConfiguration();
			let filterObj = { page: 1, perpage: 10, orderDir: this.orderDir, orderBy: this.orderBy, orderByList: this.orderByList, filter: filterConfiguration, fieldKeys: fieldsConfiguration, term: termConfiguration };
			this.apiCallSubscription = this.requestService.getDataList('case', filterObj, (data, error) => {
				if (error) {
					this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
				}
				if (data) {
					let dataResult = data.results;
					dataCasesResult = JSON.parse(JSON.stringify(dataResult));
				}
				this.loading = false;
				if (callback) {
					callback(dataCasesResult)
				}
			});
		}
	}
	private getCasesfilter() {
		let filters = {
		}
		filters['$and'] = [
			{ "organizationId._id": { "$in": [this.requestService.orgId] } }
		];
		if (!this.isAdmin && this.selectedUser.region && this.selectedUser.region !== '') {
			filters['region'] = { '$eq': this.selectedUser.region };
		}
		if (!this.isAdmin && this.selectedUser.prefecture && this.selectedUser.prefecture !== '') {
			filters['prefecture'] = { '$eq': this.selectedUser.prefecture };
		}
		return filters;
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
}
